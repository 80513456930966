import React from "react";
import classNames from "classnames";
import "./style.css";
import { BadgeColor } from "views/components/const";

interface Props {
  labelColor?: BadgeColor;
  text?: string;
  fullW?: boolean;
  onClick?: () => any;
}

const Badge = (props: Props) => {
  const className = classNames(
    "post-badge-" + (props.labelColor || "dark-gray"),
    props.fullW ? "post-badge-full" : ""
  );
  return (
    <div
      onClick={props.onClick}
      style={{ cursor: "pointer" }}
      className={className}
    >
      {props.text}
    </div>
  );
};

export default Badge;
export { Badge };
