import {
  applyMiddleware,
  combineReducers,
  createStore,
  Middleware,
  ReducersMapObject,
  Store,
  AnyAction,
} from "redux";
import { AppState } from "domains/contracts/appState";
import { composeWithDevTools } from "redux-devtools-extension";
import { History, createBrowserHistory } from "history";
import SubscriptionReducer from "./subscriptions/reducer";
import { CommonReducer } from "./common/reducer";
import PaymentMethodKYCReducer from "./paymentMethodKYC/reducer";
import ShopeeOrderReducer from "./shopeeOrder/reducer";
import DisbursementReducer from "./disbursement/reducer";
import UserReducer from "./user/reducer";
import VoucherReducer from "./voucher/reducer";

const logger: Middleware = () => (next) => (action) => {
  if (process.env.NODE_ENV !== "production") {
    console.log(action);
  }
  return next(action);
};

export function configureStore(): Store<any> {
  let middleware = applyMiddleware(logger);

  if (process.env.NODE_ENV !== "production") {
    middleware = composeWithDevTools(middleware);
  }

  let reducers: ReducersMapObject<AppState, any> = {
    common: new CommonReducer().build(),
    voucher: new VoucherReducer().build(),
    subscriptions: new SubscriptionReducer().build(),
    paymentMethodKYC: new PaymentMethodKYCReducer().build(),
    orders: new ShopeeOrderReducer().build(),
    disbursements: new DisbursementReducer().build(),
    user: new UserReducer().build(),
  };

  const appReducer = combineReducers<AppState>(reducers);

  const rootReducer = (state: AppState, action: AnyAction) => {
    if (action.type === "LOGOUT_USER") {
      state = undefined;
    }
    return appReducer(state, action);
  };

  return createStore(rootReducer, middleware);
}

export const history: History = createBrowserHistory({ basename: "/" });

export const AppStore = configureStore();
