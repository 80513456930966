import { Api } from "domains/contracts/api";
import AuthApi from "./auth";
import SubscriptionApi from "./subscription";
import PaymentMethodKYCApi from "./paymentMethodKYC";
import OrderApi from "./shopeeOrder";
import DisbursementApi from "./disbursement";
import TokofiQRISDisbursementApi from "./tokofiQRISDisbursement";
import VoucherApi from "./voucher";
import ContactlessApi from "./contactless";
import FlexpayApi from "./flexpay";
import BankApi from "./bank";

const api: Api = {
  auth: new AuthApi(),
  voucher: new VoucherApi(),
  subscriptions: new SubscriptionApi(),
  paymentMethodKYC: new PaymentMethodKYCApi(),
  order: new OrderApi(),
  disbursements: new DisbursementApi(),
  tokofiQRISDisbursements: new TokofiQRISDisbursementApi(),
  contactless: new ContactlessApi(),
  flexpay: new FlexpayApi(),
  bank: new BankApi()
};

export default api;
