import { BaseApi } from "api/base.api";
import { ISubscriptionApi } from '../../domains/subscription/contracts/api';
import { SubscriptionPackagePricing, SubscriptionSaleType, SubscriptionModel, OverrideUserSubscriptionBody, SubscriptionQueryString, SubscriptionUserType } from "domains/subscription/models";


export default class SubscriptionApi extends BaseApi implements ISubscriptionApi {
  constructor() {
    super('/admin/v2')
  }

  public async getSubscriptionPackagePricingList(): Promise<SubscriptionPackagePricing[]> {
    return this.makeForCamelCaseForList('GET', '/subscription-package-pricings');
  }

  public async getSubscriptionSaleTypeList(): Promise<SubscriptionSaleType[]> {
    return this.makeForCamelCaseForList('GET', '/subscription-sale-types');
  }

  public async overrideSubscription(subscription: OverrideUserSubscriptionBody): Promise<void> {
    return this.makeForCamelCase('POST', '/subscriptions', subscription);
  }

  public async getSubscriptionList(queryParams?: SubscriptionQueryString): Promise<SubscriptionModel[]> {
    return this.makeForCamelCaseForList('GET', '/subscriptions', queryParams);
  }

  public async cancelSubscription(subscriptionId: string): Promise<void> {
    return this.makeForCamelCase('POST', `/subscriptions/${subscriptionId}/void`);
  }

  public async getSubscriptionUserTypeList(): Promise<SubscriptionUserType[]> {
    return this.makeForCamelCaseForList('GET', '/subscriptions/user-types');
  }
}
