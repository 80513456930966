import { ICommonStorage } from "domains/commons/contracts/storage";
import { BaseService } from "domains/commons/services";
import { Api } from "domains/contracts/api";
import { toDict } from "utilities/converter/list";
import { IDisbursementStorage } from "../contract/storage";
import { ShopeeDisbursementSummaryModel, DisbursementModel, GetDisbursementQueryParam, GettingDisbursementRequest, GetShopeeDisbursementSummaryQueryParams } from "../model";

export class DisbursementService extends BaseService implements IDisbursementService {
  disbursementStorage: IDisbursementStorage;

  constructor(commonStorage: ICommonStorage, api: Api, disbursementStorage: IDisbursementStorage) {
    super(commonStorage, api);
    this.disbursementStorage = disbursementStorage;
  }

  private getLastDisbursementId = (disbursementList: DisbursementModel[]) : string | undefined => {
    return disbursementList.length > 0 ? disbursementList[disbursementList.length-1].id: undefined
  }

  public async getDisbursementList(queryParams?: GetDisbursementQueryParam) {
    try {
      const loadingKey = 'GET_DISBURSEMENT_LIST'
      this.commonStorage.setLoading(loadingKey, true);
      let disbursementReponse = await this.api.disbursements.getDisbursementList(queryParams);
      disbursementReponse = disbursementReponse ? disbursementReponse : [];
      this.disbursementStorage.setDisbursements(toDict(disbursementReponse, (s) => s.createdAt))
      const lastCategoryId = this.getLastDisbursementId(disbursementReponse)
      this.disbursementStorage.setGettingListRequest({
        ...queryParams,
        startingAfter: lastCategoryId || queryParams ? queryParams.startingAfter : ''
      } as GettingDisbursementRequest)
      this.disbursementStorage.setReachBottom(disbursementReponse.length === 0)
      this.commonStorage.setLoading(loadingKey, false);
      return disbursementReponse;
    } catch (error) {
      throw error;
    }
  }

  public async getShopeeDisbursementSummaryList(queryParams?: GetShopeeDisbursementSummaryQueryParams) {
    try {
      const shopeeDisbursementSummaryResponse = await this.api.disbursements.getShopeeDisbursementSummaryList(queryParams);
      this.disbursementStorage.setShopeeDisbursementSummaries(toDict(shopeeDisbursementSummaryResponse || [], (x) => x.details.map(d => d.orderId).join('-')))
      return shopeeDisbursementSummaryResponse;
    } catch (error) {
      throw error;
    }
  }

  public async batchCreateDisbursement(data: ShopeeDisbursementSummaryModel[]) {
    try {
      const disbursementResponse = await this.api.disbursements.batchCreateDisbursement({ data });
      return disbursementResponse;
    } catch (error) {
      throw error;
    }
  }

  public async loadMore() {
    try {
      const disbursementListManager = this.getState().disbursements.disbursementListManager;
      const queryParams = disbursementListManager.request;
      if (!queryParams.startingAfter) {
        return
      }
      if(disbursementListManager.hasReachedBottom) {
        return
      }
      if(disbursementListManager.isLoadingMore){
        return
      }
      this.disbursementStorage.setLoadingMore(true)
      const loadingKey = 'LOAD_MORE_DISBURSEMENT_LIST'
      this.commonStorage.setLoading(loadingKey, true);
      const disbursements = await this.api.disbursements.getDisbursementList(queryParams);
      this.disbursementStorage.pushDisbursements(toDict((disbursements), (p: DisbursementModel) => p.id));
      const lastDisbursementId = this.getLastDisbursementId(disbursements)
      this.disbursementStorage.setLoadingMore(false)
      this.disbursementStorage.setGettingListRequest({
        ...queryParams,
        startingAfter: lastDisbursementId || queryParams.startingAfter  
      } as GettingDisbursementRequest)
      this.disbursementStorage.setReachBottom(disbursements.length < queryParams.limit)
      this.commonStorage.setLoading(loadingKey, false);
    } catch (error) {
      throw error;
    }
  };
}

export interface IDisbursementService {
  getDisbursementList: (queryParams?: GetDisbursementQueryParam) => Promise<DisbursementModel[]>;
  getShopeeDisbursementSummaryList: (queryParams?: GetShopeeDisbursementSummaryQueryParams) => Promise<ShopeeDisbursementSummaryModel[]>;
  batchCreateDisbursement: (disbursements: ShopeeDisbursementSummaryModel[]) => Promise<DisbursementModel[]>;
  loadMore: (queryString?: GetDisbursementQueryParam) => Promise<void>;
}