import * as React from "react";
import classNames from "classnames";
import { BaseProps, BaseParentProps } from "../props";
import "./style.scss";
import { AlignmentClass } from "../const";
import { useContext } from "react";

const Context = React.createContext<GridContext>({
  capacity: undefined,
});

interface GridContext {
  capacity?: number;
}

interface ColumnProps extends BaseParentProps, BaseProps {
  align?: "center" | "left" | "right";
  colspan?: number;
}

const Column = (props: ColumnProps) => {
  let basicClassNames = classNames(
    props.className || "",
    "post-row-column-remaining",
    "post-row-column-remaining-text-align-" + (props.align || "left")
  );

  const gridContext = useContext<GridContext>(Context);

  let style: React.CSSProperties = {
    textAlign: props.align,
  };
  if (gridContext.capacity) {
    const percentage = (100 * (props.colspan || 1)) / gridContext.capacity;
    style.flexBasis = `${percentage}%`;
    style.maxWidth = `${percentage}%`;
  }
  let remaining = (
    <section style={style} className={basicClassNames}>
      <React.Fragment>{props.children}</React.Fragment>
    </section>
  );
  return remaining;
};

interface RowProps extends BaseParentProps, BaseProps {
  gutter?: 0 | 8 | 16 | 24 | 32 | 40 | 48;
  justify?: "center" | "bottom" | "top" | "normal";
  justifyContent?: AlignmentClass;
  capacity?: number;
  onClick?: () => any;
}

const Row: React.FC<RowProps> = (props: RowProps) => {
  let className = classNames(
    props.className || "",
    "post-row",
    "post-row-gutter-" + (props.gutter != undefined ? props.gutter : 0),
    "post-row-justify-" + (props.justify || "normal"),
    "post-row-justify-content-" + (props.justifyContent || "left")
  );
  return (
    <div className={className} onClick={props.onClick}>
      <Context.Provider value={{ capacity: props.capacity }}>
        <React.Fragment>{props.children}</React.Fragment>
      </Context.Provider>
    </div>
  );
};

export default {
  Row,
  Column,
};
