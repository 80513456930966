import { BaseApi } from "api/base.api";
import { IBankApi } from "domains/bank/contract/api";
import { BankModel } from "domains/bank/model";

export default class BankApi extends BaseApi implements IBankApi {
  constructor() {
    super('https://flexpay-api.dev.post.app')
  }

  public async getBankList(): Promise<BankModel[]> {
    return await this.makeForCamelCase('GET', '/bank/v1/all')
  }
}
