import { EnvConfig } from "./def";

export default {
  googleClientId:
    "837117142790-b1m3r840rq5c79bfbi1dmsfa5q3i7pi6.apps.googleusercontent.com",
  featureFlags: {
    subscription: {
      subscriptionPage: true,
      voucherPage: true,
    },
    cashlez: {
      cashlezKYC: true,
    },
    shopee: {
      shopeeKYC: true,
      shopeeTransaction: true,
      disbursement: true,
    },
    contactless: {
      contactless: true,
    },
    flexpay: {
      flexpay: true,
      invoice: true,
      payment: true,
      vendor: true,
      user: true,
      bill: true,
      repayment: true
    },
    miscellaneous: {
      role: true,
    },
  },
} as EnvConfig;
