import React from "react";
import { FileDrop } from "react-file-drop";
import "./style.css";

interface DropZoneProps {
  children: any;
  onDropFile: (file: File) => void;
}

const DropZone = (props: DropZoneProps) => {
  return (
    <div className="file-drop-container">
      <FileDrop onDrop={(files) => props.onDropFile(files[0])}>
        {props.children}
      </FileDrop>
    </div>
  );
};

export default DropZone;
