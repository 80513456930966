import * as React from "react";
import classNames from "classnames";
import { BaseParentProps, BaseProps, BaseClickableProps } from "../props";
import "./style.scss";

interface HeaderProps extends BaseParentProps, BaseProps {
  style?: React.CSSProperties;
}
const Header = (props: HeaderProps) => {
  let className = classNames(
    props.className || "",
    "post-panel-segment",
    "post-panel-header"
  );
  const style: React.CSSProperties = { ...props.style };
  return (
    <header className={classNames(className)} style={style}>
      <React.Fragment>{props.children}</React.Fragment>
    </header>
  );
};

interface ContentProps extends BaseParentProps, BaseProps {
  padding?: 8 | 16 | 24 | 32;
}

const Content = (props: ContentProps) => {
  let basicClassNames = classNames(
    props.className || "",
    "post-panel-segment",
    "post-panel-content"
  );
  let style: React.CSSProperties = {
    padding: props.padding || 16,
    ...props.style,
  };
  let content = (
    <section style={style} className={basicClassNames}>
      <React.Fragment>{props.children}</React.Fragment>
    </section>
  );
  return content;
};

interface FooterProps extends BaseProps, BaseParentProps {
  bgColor?: "gray";
  padding?: 8 | 16 | 24 | 32;
}

const Footer = (props: FooterProps) => {
  let style: React.CSSProperties = {
    padding: props.padding || 16,
  };
  let footerClassname = classNames(
    props.className || "",
    "post-panel-segment",
    "post-panel-footer",
    props.bgColor ? "post-panel-footer-bg-color-" + props.bgColor : ""
  );
  return (
    <footer className={footerClassname} style={style}>
      <React.Fragment>{props.children}</React.Fragment>
    </footer>
  );
};

interface PanelProps extends BaseParentProps, BaseProps {
  type?: "rounded";
  noborder?: boolean;
}

const Panel: React.FC<PanelProps> = (props: PanelProps) => {
  let type = props.type ? props.type : "rounded";
  let className = classNames(
    "post-panel",
    "post-panel-" + type,
    props.noborder ? "post-panel-noborder" : "post-panel-border"
  );
  return (
    <div className={className}>
      <React.Fragment>{props.children}</React.Fragment>
    </div>
  );
};

interface CardProps extends BaseParentProps, BaseClickableProps {
  minHeight?: number;
  padding?: 8 | 10 | 16 | 24 | 32;
  style?: React.CSSProperties;
}
const Card: React.FC<CardProps> = (props: CardProps) => {
  let className = classNames(
    props.className || "",
    "post-panel",
    "post-panel-card"
  );
  let style: React.CSSProperties = {
    padding: props.padding || 0,
    ...props.style,
  };
  if (props.minHeight) {
    style = {
      ...style,
      minHeight: props.minHeight,
    };
  }

  return (
    <div style={style} className={className} onClick={props.onClick}>
      <React.Fragment>{props.children}</React.Fragment>
    </div>
  );
};

export default {
  Panel,
  Header,
  Content,
  Footer,
  Card,
};
