import { BaseService } from "domains/commons/services";
import { ContactlessMerchant, ContactlessMerchantDetail, ContactlessMerchantStatus, GetMerchantQueryParams } from "../model";

export interface IContactlessService {
  getMerchantList: (queryParam?: GetMerchantQueryParams) => Promise<ContactlessMerchant[]>
  getMerchantDetail: (userId: string) => Promise<ContactlessMerchantDetail>
  suspendMerchant: (userId: string) => Promise<void>
  getMerchantStatus: (userId: string) => Promise<ContactlessMerchantStatus>
}

export class ContactlessService extends BaseService implements IContactlessService {
  public getMerchantList(queryParam?: GetMerchantQueryParams) {
    return this.api.contactless.getMerchantList(queryParam)
  }

  public getMerchantDetail(userId: string) {
    return this.api.contactless.getMerchantDetail(userId)
  }

  public suspendMerchant(userId: string) {
    return this.api.contactless.suspendMerchant(userId)
  }

  public getMerchantStatus(userId: string) { 
    return this.api.contactless.getMerchantStatus(userId)
  }
}