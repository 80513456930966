import { BaseParentProps, BaseProps } from '../props'
import React, { useRef, useEffect } from 'react'
import classNames from 'classnames';

interface Props extends BaseParentProps, BaseProps {
    isDisabled?: boolean
    alwaysRenderOverlay?: boolean
    render: (action: DropdownAction) => [React.ReactNode, React.ReactNode]
    onVisibilityChanged?: (isVisibility: boolean) => any
    onClose?: () => any
    style?:any
}

export interface DropdownAction {
    setVisibility: (isVisible: boolean) => any
    toggle: () => any
    isVisible: boolean
}

export const Dropdown = (props: Props) => {
    const [isVisible, setVisibility] = React.useState<boolean>(false)
    const containerRef = useRef<HTMLDivElement>(null)

    const isVisibleRef = React.useRef(isVisible)

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick, false);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
          };
    }, []);


    const handleOutsideClick = (e: any) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            if(isVisibleRef.current){
                close()
            }
        }
    }

    const open = () => {
        isVisibleRef.current = true
        setVisibility(true)
        props.onVisibilityChanged && props.onVisibilityChanged(true)
    
    }
    const close = () => {
        isVisibleRef.current = false
        setVisibility(false)
        props.onClose && props.onClose()
        props.onVisibilityChanged && props.onVisibilityChanged(false)    
    }


    const [content, overlay] = props.render({
        setVisibility: (x: boolean) => {
            x? open(): close()
            setVisibility(x)
        }, 
        toggle: () =>  {
            isVisible? close(): open()
        },
        isVisible
    })

    const showOverlay = props.alwaysRenderOverlay || (isVisible && !props.isDisabled)

    const className = classNames(props.className || '')

    return <div ref={containerRef} style={props.style} className={className}>
        {content}
        {showOverlay && overlay}
    </div>
}

