import { BaseApi } from "api/base.api";
import { IShopeeOrderApi } from "domains/shopeeOrder/contracts/api";
import { ShopeeOrderModel, GetShopeeOrderQueryString } from "domains/shopeeOrder/models";

export default class OrderApi extends BaseApi implements IShopeeOrderApi {
  constructor() {
    super('/admin/v2')
  }

  public async getShopeeOrderList(queryParams?: GetShopeeOrderQueryString): Promise<ShopeeOrderModel[]> {
    return this.makeForCamelCaseForList('GET', '/shopee/orders', queryParams);
  }
}
