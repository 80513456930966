import { ICommonStorage } from "../contracts/storage";
import { AlertConfig } from "../states";
import { Api } from "domains/contracts/api";
import { AppState } from "domains/contracts/appState";

export class BaseService{
    commonStorage: ICommonStorage
    api: Api

    constructor(commonStorage: ICommonStorage, api: Api){
        this.commonStorage = commonStorage
        this.api = api
    }

    setLoading(label: string, isLoading: boolean){
        this.commonStorage.setLoading(label, isLoading)
    }

    setAlert(alert: AlertConfig){
        this.commonStorage.setAlertConfig(alert)
    }

    getState(): AppState{
        return this.commonStorage.getState()
    }


}


export class CommonService extends BaseService implements ICommonService {
    removeModal(id: string){
        this.commonStorage.removeModalLayer(id)
    }
    pushModal(modal: {id:string}){
        this.commonStorage.pushModalLayer(modal)
    }

    setModals(modals: {id:string}[]){
        this.commonStorage.setModalLayers(modals)
    }

}


export interface ICommonService{
    setLoading(label: string, isLoading: boolean):void
    setAlert(alert: AlertConfig):void
    pushModal(modal: {id:string}):void
    setModals(modals: {id:string}[]):void
    removeModal(id: string):void
}