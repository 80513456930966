import { Store } from "redux";
import { AppState } from "domains/contracts/appState";


export class BaseStorage {
    private store: Store;
  
    constructor(store: Store<AppState>) {
      this.store = store;
    }
  
    protected getState(): Readonly<AppState> {
      return this.store.getState();
    }
  
    protected dispatch(type: string, payload?: any): void {
      this.store.dispatch({ type, payload })
    }
  }
  