
import ROUTE from 'constants/routes/index'
import { RouteItem } from 'utilities/router/route.item'
import { Guest } from 'middleware/guest';

export const authRoutes: Array<RouteItem> = [
  {
    key: 'login',
    text: 'Login',
    path: ROUTE.LOGIN,
    component: 'views/modules/Login',
    middleware: [Guest],
    isEnabled: true
  }
]
