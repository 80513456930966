import { BaseService } from "domains/commons/services";
import { BankModel } from "../model";

export interface IBankService {
  getBankList: () => Promise<BankModel[]>;
}

export class BankService extends BaseService implements IBankService {
  public async getBankList() {
    return await this.api.bank.getBankList();
  }
}