import React from 'react'
import { withRouter } from 'react-router';
import { Wrapper, SwitchRoute } from 'views/components';
import { AUTH_ROUTES } from 'routes/auth-routes';

const UnauthorizedLayout: React.FC = () => {
  return (
    <Wrapper>
      <SwitchRoute routes={AUTH_ROUTES} />
    </Wrapper>
  )
}

export default withRouter(UnauthorizedLayout)
