import { BaseApi } from "api/base.api";
import { IVoucherApi } from "domains/voucher/contracts/api";
import { VoucherModel, VoucherQueryString, VoucherRuleModel } from "domains/voucher/models";

export default class VoucherApi extends BaseApi implements IVoucherApi {
  constructor() {
    super('/admin/v2')
  }

  public async getVoucherList(queryParams?: VoucherQueryString): Promise<VoucherModel[]> {
    return this.makeForCamelCaseForList('GET', '/subscription-vouchers', queryParams);
  }

  public async getVoucherRules(): Promise<VoucherRuleModel[]> {
    return this.makeForCamelCaseForList('GET', '/subscription-vouchers/rules');
  }

  public async getVoucher(id: string): Promise<VoucherModel> {
    return this.makeForCamelCase('GET', `/subscription-vouchers/${id}`);
  }

  public async createVoucher(payload: VoucherModel): Promise<VoucherModel> {
    return this.makeForCamelCase('POST', `/subscription-vouchers`, payload);
  }

  public async updateVoucher(payload: VoucherModel, code: string): Promise<VoucherModel> {
    return this.makeForCamelCase('POST', `/subscription-vouchers/${code}`, payload);
  }
}
