import { UserModel } from "domains/user/models";
import { UserState } from "domains/user/state";
import { Reducer } from "utilities/redux/reducer";
import { SET_USER } from "./actions";

export default class UserReducer extends Reducer<UserState> {
  constructor() {
    super({} as UserModel)
  }

  public setUser(state: UserState, user: UserModel): UserState {
    return {
      ...state,
      ...user
    }
  }

  get actions(): {
    [p: string]: (state: UserState, payload?: any) => any;
  } {
    return {
      [SET_USER]: this.setUser
    };
  }
}
