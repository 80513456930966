import ROUTE from "constants/routes/index";
import { RouteItem } from "utilities/router/route.item";
import { Authenticated } from "middleware/authenticate";
import { isAnyOfTheseFeaturesEnabled, featureCheck } from "config";

export const appRoutes: Array<RouteItem> = [
  {
    key: "subscription",
    text: "Subscription",
    path: ROUTE.SUBSCRIPTION,
    component: "views/modules/Subscription",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(
      featureCheck.subscription.subscriptionPage
    ),
  },
  {
    key: "voucher",
    text: "Subscription Voucher",
    path: ROUTE.SUBSCRIPTION_VOUCHER,
    component: "views/modules/Voucher",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(
      featureCheck.subscription.voucherPage
    ),
  },
  {
    key: "voucher-create",
    text: "",
    path: ROUTE.SUBSCRIPTION_VOUCHER_CREATE,
    component: "views/modules/Voucher/CreateVoucherPage",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(
      featureCheck.subscription.voucherPage
    ),
  },
  {
    key: "voucher-edit",
    text: "",
    path: ROUTE.SUBSCRIPTION_VOUCHER_EDIT,
    component: "views/modules/Voucher/EditVoucherPage",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(
      featureCheck.subscription.voucherPage
    ),
  },
  {
    key: "cashlezKYC",
    text: "Cashlez KYC",
    path: ROUTE.CASHLEZ_KYC,
    component: "views/modules/CashlezKYC",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.cashlez.cashlezKYC),
  },
  {
    key: "cashlezKYCDetail",
    text: "",
    path: ROUTE.CASHLEZ_KYC_DETAIL,
    component: "views/modules/CashlezKYC/CashlezKYCDetail",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.cashlez.cashlezKYC),
  },
  {
    key: "shopeePayKYC",
    text: "ShopeePay KYC",
    path: ROUTE.SHOPEEPAY_KYC,
    component: "views/modules/ShopeePayKYC",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.shopee.shopeeKYC),
  },
  {
    key: "shopeePayKYCDetail",
    text: "",
    path: ROUTE.SHOPEEPAY_KYC_DETAIL,
    component: "views/modules/ShopeePayKYC/ShopeePayKYCDetail",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.shopee.shopeeKYC),
  },
  {
    key: "shopeeOrder",
    text: "ShopeePay Transaction",
    path: ROUTE.TRANSACTION_REPORT,
    component: "views/modules/ShopeeOrder",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(
      featureCheck.shopee.shopeeTransaction
    ),
  },
  {
    key: "disbursement",
    text: "Disbursement",
    path: ROUTE.DISBURSEMENT,
    component: "views/modules/Disbursement",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.shopee.disbursement),
  },
  {
    key: "tokofiQRISDisbursement",
    text: "Tokofi & QRIS Disbursement",
    path: ROUTE.TOKOFI_QRIS_DISBURSEMENT,
    component: "views/modules/TokofiQRISDisbursement",
    middleware: [Authenticated],
    isEnabled: true,
  },
  {
    key: "tokofiQRISDisbursementBulkUpdate",
    text: "",
    path: ROUTE.TOKOFI_QRIS_DISBURSEMENT_BULK_UPDATE,
    component: "views/modules/TokofiQRISDisbursement/BulkUpdate",
    middleware: [Authenticated],
    isEnabled: true,
  },
  {
    key: "contactless",
    text: "EasyOrder User",
    path: ROUTE.CONTACTLESS,
    component: "views/modules/Contactless/ContactlessUserList",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(
      featureCheck.contactless.contactless
    ),
  },
  {
    key: "contactless",
    text: "",
    path: ROUTE.CONTACTLESS_DETAIL,
    component: "views/modules/Contactless/ContactlessUserDetail",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(
      featureCheck.contactless.contactless
    ),
  },
  {
    key: "flexpayInvoice",
    text: "FlexPay - Invoice List",
    path: ROUTE.FLEXPAY_INVOICE,
    component: "views/modules/Flexpay/FlexpayInvoiceTable",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.flexpay.invoice),
  },
  {
    key: "flexpayInvoiceDetail",
    text: "",
    path: ROUTE.FLEXPAY_INVOICE_DETAIL,
    component: "views/modules/Flexpay/FlexpayInvoiceDetail",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.flexpay.invoice),
  },
  {
    key: "flexpayPayment",
    text: "FlexPay - Payment List",
    path: ROUTE.FLEXPAY_PAYMENT,
    component: "views/modules/Flexpay/FlexpayPaymentTable",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.flexpay.payment),
  },
  {
    key: "flexpayPaymentDetail",
    text: "",
    path: ROUTE.FLEXPAY_PAYMENT_DETAIL,
    component: "views/modules/Flexpay/FlexpayPaymentDetail",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.flexpay.payment),
  },
  {
    key: "flexpayPaymentCreation",
    text: "",
    path: ROUTE.FLEXPAY_PAYMENT_CREATE,
    component: "views/modules/Flexpay/FlexpayPaymentCreation",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.flexpay.payment),
  },
  {
    key: "flexpayVendor",
    text: "FlexPay - Vendor List",
    path: ROUTE.FLEXPAY_VENDOR,
    component: "views/modules/Flexpay/FlexpayVendorTable",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.flexpay.vendor),
  },
  {
    key: "flexpayVendorDetail",
    text: "",
    path: ROUTE.FLEXPAY_VENDOR_DETAIL,
    component: "views/modules/Flexpay/FlexpayVendorDetail",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.flexpay.vendor),
  },
  {
    key: "flexpayVendorCreate",
    text: "",
    path: ROUTE.FLEXPAY_VENDOR_CREATE,
    component: "views/modules/Flexpay/FlexpayVendorCreation",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.flexpay.vendor),
  },
  {
    key: "flexpayUser",
    text: "FlexPay - User List",
    path: ROUTE.FLEXPAY_USER,
    component: "views/modules/Flexpay/FlexpayUserTable",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.flexpay.user),
  },
  {
    key: "flexpayUserDetail",
    text: "",
    path: ROUTE.FLEXPAY_USER_DETAIL,
    component: "views/modules/Flexpay/FlexpayUserDetail",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.flexpay.user),
  },
  {
    key: "flexpayUserDetailWallet",
    text: "",
    path: ROUTE.FLEXPAY_USER_DETAIL_WALLET,
    component: "views/modules/Flexpay/FlexpayUserDetailWallet",
    middleware: [Authenticated],
    isEnabled: true,
  },

  {
    key: "flexpayAccumulativeAmountDue",
    text: "",
    path: ROUTE.FLEXPAY_ACCUMULATIVE_AMOUNT_DUE,
    component: "views/modules/Flexpay/FlexpayAccumulativeAmountDue",
    middleware: [Authenticated],
    isEnabled: true,
  },
  {
    key: "flexpayBillPayment",
    text: "FlexPay - Bill Payment",
    middleware: [Authenticated],
    children: [
      {
        key: "flexpayBillDailyTable",
        text: "Daily",
        path: ROUTE.FLEXPAY_BILL_DAILY,
        middleware: [Authenticated],
        component: "views/modules/Flexpay/FlexpayBillDailyTable",
        isEnabled: true,
      },
      {
        key: "flexpayBillPayment",
        text: "Monthly",
        path: ROUTE.FLEXPAY_BILL,
        middleware: [Authenticated],
        component: "views/modules/Flexpay/FlexpayBillTable",
        isEnabled: true,
      },
    ],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.flexpay.bill),
  },
  {
    key: "flexpayBillCreate",
    text: "",
    path: ROUTE.FLEXPAY_BILL_CREATE,
    component: "views/modules/Flexpay/FlexpayBillCreation",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.flexpay.bill),
  },
  {
    key: "flexpayBillPaymentDetail",
    text: "",
    path: ROUTE.FLEXPAY_BILL_DETAIL,
    component: "views/modules/Flexpay/FlexpayBillDetail",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.flexpay.bill),
  },
  {
    key: "flexpayBillDailyDetail",
    text: "",
    path: ROUTE.FLEXPAY_BILL_DETAIL_DAILY,
    component: "views/modules/Flexpay/FlexpayBillDailyDetail",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.flexpay.bill),
  },
  {
    key: "flexpayRepayment",
    text: "FlexPay - Repayment List",
    path: ROUTE.FLEXPAY_REPAYMENT,
    component: "views/modules/Flexpay/FlexpayRepaymentTable",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.flexpay.repayment),
  },
  {
    key: "flexpayRepaymentDetail",
    text: "",
    path: ROUTE.FLEXPAY_REPAYMENT_DETAIL,
    component: "views/modules/Flexpay/FlexpayRepaymentDetail",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.flexpay.repayment),
  },
  {
    key: "flexpayRepaymentCreate",
    text: "",
    path: ROUTE.FLEXPAY_REPAYMENT_CREATE,
    component: "views/modules/Flexpay/FlexpayRepaymentCreation",
    middleware: [Authenticated],
    isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.flexpay.repayment),
  },
];
