import { Action } from './action'
import { SingleReducer } from './reducer';
import { Dict } from 'utilities/type';

export function reducerGenerators<T>(initialState: T, reducer: Dict<SingleReducer<T>>): SingleReducer<T> {
  return (state= initialState, action: Action) => {
    return reducer[action.type]
    ? reducer[action.type](state, action.payload)
    : state;
  }
}