import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  Method,
} from 'axios';
import { preRequestInterceptor } from './interceptor/preRequest';
import { errorInterceptor } from './interceptor/error'
import { toSnakeCase, toCamelCase } from './converter';
import { getAppConfig } from 'config';

export class BaseApi {
  private baseUrl: string;
  private addtionalBaseUrl: string;
  private axiosInstance: AxiosInstance;

  constructor(addtionalBaseUrl = '') {
    this.baseUrl = getAppConfig().apiUrl || '';
    this.addtionalBaseUrl = addtionalBaseUrl;
    this.axiosInstance = axios.create();

    this.axiosInstance.interceptors.request.use(
      preRequestInterceptor,
      (err) => Promise.reject(err),
    );

    this.axiosInstance.interceptors.response.use(
      (response) => response.data,
      errorInterceptor,
    );
  }

  async make<T>(method: Method, url: string, data: any = {}, additionalConfig = {}, headers: object = {}): Promise<T> {
    let header: object = {
      'Content-Type': 'application/json'
    }

    if (Object.keys(headers).length > 0) {
      header = headers
    }

    const config: AxiosRequestConfig = {
      baseURL: this.baseUrl,
      method: <Method>method,
      url: `${this.addtionalBaseUrl}${url}`,
      headers: header,
      ...additionalConfig,
    };

    if (method === 'GET') {
      Object.keys(data).forEach((key) => {
        if (data[key] === null || data[key] === '') {
          delete data[key];
        }
      });
      console.error(data)
      config.params = data;
    } else {
      config.data = data;
    }

    return this.axiosInstance.request(config);
  }

  async makeForCamelCase<T>(method: Method, url: string, data: any = {}, additionalConfig = {}, headers: object = {}): Promise<T> {
    let obj = await this.make(method, url, toSnakeCase(data), additionalConfig, headers)
    return toCamelCase(obj)
  }

  async makeForCamelCaseForList<Item>(method: Method, url: string, data: any = {}, additionalConfig = {}, headers: object = {}): Promise<Item[]> {
    let obj = await this.make(method, url, toSnakeCase(data), additionalConfig, headers)
    return (toCamelCase(obj) as ListResponse<Item>).data
  }

}


interface ListResponse<T> {
  data: T[]
}