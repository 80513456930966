import React from 'react'
import InputBase from '../base'
import Form, { RenderingFieldContext } from '../../Form'

interface Props {
  label?: string;
  placeholder?: string;
  name?: string;
}

const InputEmail = (props: Props) => {
  return (
    <Form.Field
      name={props.name}
      render={({ field, value, isDisabled, onChange, onBlur }: RenderingFieldContext) => (
        <InputBase isDisabled={isDisabled} label={props.label} error={field.isDirty && field.error}>
          <input
            value={value}
            onBlur={onBlur}
            disabled={isDisabled}
            onChange={(evt) => onChange(evt.target.value)}
            name={props.name} type='text'
            placeholder={props.placeholder} />
        </InputBase>
      )}
    />
  )
}

export default InputEmail;
