// import { AppState } from 'domains/contracts/appState';
// import { UserState } from "domains/users/states";
// import { SubscriptionAlert } from 'domains/subscriptions/model'
import { RouteDescription, toRouteDescription } from 'utilities/router/route.item';

export interface SidebarViewModel{
    // user: UserState,
    // subscriptionAlert: SubscriptionAlert,
    selectedRoute: RouteDescription,
}


export default (state: any): SidebarViewModel => ({
    selectedRoute: toRouteDescription(state.layout.sidebarStates.currentRoute),
    // user: state.user,
    // subscriptionAlert: state.subscriptions.subscriptionAlert
})
