import { AxiosRequestConfig } from 'axios'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import { getLocalStorage } from 'utilities/localStorage'

export const preRequestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = getLocalStorage(CONFIG_LOCALSTORAGE.POST_TOKEN)
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`
    }
  }
  return config
}
