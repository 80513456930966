
export type ItemAssessor<T> = (item: T) => boolean

export type Executor<T> = (f: ItemAssessor<T>) => boolean


function _allOf<T>(...items: T[]): Executor<T> {
    console.log("ALL OF", items)
    return (f: ItemAssessor<T>) => {
        console.log("EXEC ALL OF", items)
        const result = items
        .map(i => {
            const y = f(i)
            console.log("\t",i, y)
            return y
        })
        .reduce((x, y) => x && y, true)
        console.log("CONCLUDE ALL OF", items, result)
        return result;
    }
}

function _anyOf<T>(...items: T[]): Executor<T> {
    console.log("ANY OF", items)
    return (f: ItemAssessor<T>) => {
        console.log("EXEC ANY OF", items)
        const result = items
        .map(i => {
            const y = f(i)
            console.log("\t",i, y)
            return y
        })
        .reduce((x, y) => x || y, false)
        console.log("CONCLUDE ANY OF", items, result)
        return result;

    }
}
function _or<T>(...ors: Executor<T>[]): Executor<T> {
    console.log("OR")
    return (f: ItemAssessor<T>) => {
        console.log("EXEC OR")
        const result =  ors.map(a => a(f))
        .map((x,i) => {
            console.log("OR", i, x)
            return x
        })
        .reduce((x, y) => x || y, false)
        console.log("CONCLUDE OR:", result)
        return result;

    }
}

function _and<T>(...ands: Executor<T>[]): Executor<T> {
    console.log("AND")
    return (f: ItemAssessor<T>) => {
        console.log("EXEC AND")
        const result =  ands.map(a => a(f))
        .map((x, i) => {
            console.log("AND", i, x)
            return x
        })
        .reduce((x, y) => x && y, true)
        console.log("CONCLUDE AND:", result)
        return result;

    }
}
function _not<T>(a: Executor<T>): Executor<T> {
    console.log("NOT")
    return (f: ItemAssessor<T>) => {
        console.log("EXEC NOT")
        let output = a(f)
        console.log("CONCUDE NOT", output, !output)
        return !output
    }
}

function _validFor<T>(e: Executor<T>, f: ItemAssessor<T>): boolean {
    console.log("VALID")
    return e(f)
}


function createOperator<T>(prev: Executor<T>, operator: (...xxx: Executor<T>[]) => Executor<T>): _BinaryOperator<T> {
    return new _BinaryOperator<T>(prev, operator)
}

function createOperand<T>(prev: Executor<T>) {
    return new _Operand<T>(prev)
}

export interface BinaryOperator<T> {
    not: _BinaryOperator<T>
    anyOf(...items: T[]): Operand<T>
    allOf(...items: T[]): Operand<T>
}

class _BinaryOperator<T> implements BinaryOperator<T>{
    prev: Executor<T>
    operator: (left: Executor<T>, right: Executor<T>) => Executor<T>
    constructor(prev: Executor<T>, operator: (left: Executor<T>, right: Executor<T>) => Executor<T>) {
        this.prev = prev
        this.operator = operator 
    }

    get not(): _BinaryOperator<T> {
        return createOperator(this.prev, (a,b) => this.operator(a, _not(b)))
    }
    anyOf(...items: T[]): Operand<T> {
        return createOperand(this.operator(this.prev, _anyOf(...items)))
    }
    allOf(...items: T[]): Operand<T> {
        return createOperand(this.operator(this.prev, _allOf(...items)))
    }
}

export interface Operand<T> {
    and: BinaryOperator<T>
    or: BinaryOperator<T>
    validFor(i: ItemAssessor<T>): boolean

}

class _Operand<T> implements Operand<T>{
    prev: Executor<T>

    constructor(prev: Executor<T>) {
        this.prev = prev
    }

    get and(): BinaryOperator<T> {
        return createOperator(this.prev, _and)
    }

    get or(): BinaryOperator<T> {
        return createOperator(this.prev, _or)
    }

    validFor(i: ItemAssessor<T>): boolean {
        return _validFor(this.prev, i)
    }
}

export function not<T>(): BinaryOperator<T>{
    return createOperator(() => true, (first, second) => _not(second))
}
export function anyOf<T>(...items: T[]): Operand<T>{
    return  createOperand(_anyOf(...items))
}
export function allOf<T>(...items: T[]): Operand<T>{
    return  createOperand(_allOf(...items))
}
export function is<T>(): BinaryOperator<T> {
    return createOperator(() => true, (first, second) => second)
}


