import { DisbursementModel, ShopeeDisbursementSummaryModel, GettingDisbursementRequest } from "domains/disbursement/model";
import { DisbursementState } from "domains/disbursement/state";
import { Reducer } from "utilities/redux/reducer";
import { Dict } from "utilities/type";
import { PUSH_DISBURSEMENT, SET_DISBURSEMENT, SET_DISBURSEMENT_LIST_REACH_BOTTOM, SET_SHOPEE_DISBURSEMENT_SUMMARY } from "./actions";

export default class DisbursementReducer extends Reducer<DisbursementState> {
  constructor() {
    super({
      disbursementDict: {},
      shopeeDisbursementSummariesDict: {},
      disbursementListManager: {
        isLoadingMore: false,
        request: {},
        hasReachedBottom: false
      }
    })
  }

  public setDisbursements(state: DisbursementState, disbursementDict: Dict<DisbursementModel>) {
    return {
      ...state,
      disbursementDict
    };
  };

  public setShopeeDisbursementSummaries(state: DisbursementState, shopeeDisbursementSummariesDict: Dict<ShopeeDisbursementSummaryModel>) {
    return {
      ...state,
      shopeeDisbursementSummariesDict
    }
  }

  public pushDisbursements(state: DisbursementState, disbursementDict: Dict<DisbursementModel>) {
    return {
      ...state,
      disbursementDict: {
        ...state.disbursementDict,
        ...disbursementDict
      }
    };
  };
  
  public setListReachBottom(state: DisbursementState, hasReachedBottom: boolean): DisbursementState {
    return {
      ...state,
      disbursementListManager: {
        ...state.disbursementListManager,
        hasReachedBottom
      }
    }
  }

  public setLoadingMore(state: DisbursementState, isLoadingMore: boolean): DisbursementState {
    return {
      ...state,
      disbursementListManager: {
        ...state.disbursementListManager,
        isLoadingMore
      }
    }
  }


  public setGettingListRequest(state: DisbursementState, request: GettingDisbursementRequest): DisbursementState {
    return {
      ...state,
      disbursementListManager: {
        ...state.disbursementListManager,
        ...request
      }
    }
  }

  get actions(): {
    [p: string]: (state: DisbursementState, payload?: any) => any;
  } {
    return {
      [SET_DISBURSEMENT]: this.setDisbursements,
      [SET_SHOPEE_DISBURSEMENT_SUMMARY]: this.setShopeeDisbursementSummaries,
      [PUSH_DISBURSEMENT]: this.pushDisbursements,
      [SET_DISBURSEMENT_LIST_REACH_BOTTOM]: this.setListReachBottom
    };
  }
}