import { BaseService } from "domains/commons/services";
import { ICommonStorage } from "domains/commons/contracts/storage";
import { Api } from "domains/contracts/api";
import { IVoucherStorage } from "../contracts/storage";
import { toDict } from "utilities/converter/list";
import { GettingVouchersRequest, VoucherModel, VoucherQueryString, VoucherRuleModel } from "../models";

export class VoucherService extends BaseService implements IVoucherService {
  voucherStorage: IVoucherStorage;

  constructor(commonStorage: ICommonStorage, api: Api, voucherStorage: IVoucherStorage) {
    super(commonStorage, api);
    this.voucherStorage = voucherStorage;
  }

  private getLastVoucherId = (subscriptions: VoucherModel[]) : string | undefined => {
    return subscriptions.length > 0 ? subscriptions[subscriptions.length-1].code: undefined
  }

  public async getVoucherList(queryParams?: VoucherQueryString) {
    try {
      const loadingKey = 'GET_VOUCHER_LIST'
      this.commonStorage.setLoading(loadingKey, true);
      const voucherReponse = await this.api.voucher.getVoucherList(queryParams);
      if(voucherReponse !== null){
        this.voucherStorage.setVouchers(toDict(voucherReponse, (s) => s.code))
        const lastCategoryId = this.getLastVoucherId(voucherReponse)
        this.voucherStorage.setGettingVoucherListRequest({
          ...queryParams,
          startingAfter: lastCategoryId || queryParams.startingAfter  
        } as GettingVouchersRequest)
        this.voucherStorage.setVouchersReachBottom(voucherReponse.length === 0)
        this.commonStorage.setLoading(loadingKey, false);
        return voucherReponse;
      } else {
        this.commonStorage.setLoading(loadingKey, false);
        return []
      }
    } catch (error) {
      throw error;
    }
  }

  public async loadMore() {
    try {
      const voucherListManager = this.getState().voucher.voucherListManager;
      const queryParams = voucherListManager.request;
      if (!queryParams.startingAfter) {
        return
      }
      if(voucherListManager.hasReachedBottom) {
        return
      }
      if(voucherListManager.isLoadingMoreVoucher){
        return
      }
      this.voucherStorage.setLoadingMoreVouchers(true)
      const loadingKey = 'LOAD_MORE_VOUCHER_LIST'
      this.commonStorage.setLoading(loadingKey, true);
      const vouchers = await this.api.voucher.getVoucherList(queryParams);
      if(vouchers !== null){
        this.voucherStorage.pushVouchers(toDict((vouchers), (p: VoucherModel) => p.code));
        const lastVoucherId = this.getLastVoucherId(vouchers)
        this.voucherStorage.setLoadingMoreVouchers(false)
        this.voucherStorage.setGettingVoucherListRequest({
          ...queryParams,
          startingAfter: lastVoucherId || queryParams.startingAfter  
        } as GettingVouchersRequest)
        this.voucherStorage.setVouchersReachBottom(vouchers.length < queryParams.limit)
      } else {
        this.voucherStorage.setLoadingMoreVouchers(false)
        this.voucherStorage.setVouchersReachBottom(true)
      }
      this.commonStorage.setLoading(loadingKey, false);
    } catch (error) {
      throw error;
    }
  };

  public async getVoucherRules() {
    try {
      const voucherRulesReponse = await this.api.voucher.getVoucherRules();
      if(voucherRulesReponse !== null){
        this.voucherStorage.setVoucherRules(toDict(voucherRulesReponse, (s) => s.id))
      }
      return voucherRulesReponse;
    } catch (error) {
      throw error;
    }
  }

  public async createVoucher(payload: VoucherModel) {
    try {
      await this.api.voucher.createVoucher(payload);
    } catch (error) {
      throw error;
    }
  }

  public async updateVoucher(payload: VoucherModel, code: string) {
    try {
      await this.api.voucher.updateVoucher(payload, code);
    } catch (error) {
      throw error;
    }
  }
}

export interface IVoucherService {
  getVoucherList: (queryParams?: VoucherQueryString) => Promise<VoucherModel[]>;
  getVoucherRules: () => Promise<VoucherRuleModel[]>;
  loadMore: (queryString: VoucherQueryString) => Promise<void>;
  createVoucher: (payload: VoucherModel) => Promise<void>;
  updateVoucher: (payload: VoucherModel, code: string) => Promise<void>;
}
