import React from "react";
import { useTable, useSortBy, Column } from "react-table";
import classNames from "classnames";
import { BaseProps } from "../props";
import { Text, Icon } from "views/components";
import "./style.css";

export type ReactColumn<D extends object = {}> = Column<D> & {
  headerAlign?: string;
};

interface TableProps extends BaseProps {
  columns: any;
  data: any[];
  withIndex: boolean;
  withFooter: boolean;
  type?: "bordered" | "striped" | "hovered";
  size?: "default" | "fat";
}

const ReactTable = (props: TableProps) => {
  const { columns, data } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <div className="post-table" style={{ ...props.style }}>
      <table
        {...getTableProps()}
        className={classNames(
          props.className || "",
          "post-table-type-" + (props.type || "striped"),
          "post-table-size-" + (props.size || "default")
        )}
      >
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="post-table-tr"
            >
              {props.withIndex && <th>No</th>}
              {headerGroup.headers.map((column: any) => (
                <td
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="post-table-td"
                  style={{
                    cursor: !column.disableSortBy ? "pointer" : "unset",
                  }}
                >
                  <div
                    style={{
                      float: column.headerAlign || "unset",
                      display: "flex",
                    }}
                  >
                    <Text.Heading h={6} style={{ alignSelf: "center" }}>
                      {column.render("Header")}
                    </Text.Heading>
                    {!column.disableSortBy && (
                      <div style={{ alignSelf: "center" }}>
                        <Icon.SortCaretup
                          size={10}
                          color={
                            column.isSorted && !column.isSortedDesc
                              ? "blue"
                              : "default"
                          }
                          className="sort-icon"
                        />
                        <Icon.SortCaretdown
                          size={10}
                          color={
                            column.isSorted && column.isSortedDesc
                              ? "blue"
                              : "default"
                          }
                          className="sort-icon"
                        />
                      </div>
                    )}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any, i: number) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="post-table-tr">
                {props.withIndex && (
                  <td>
                    <Text.Paragraph size={14} color="black">
                      {i + 1}
                    </Text.Paragraph>
                  </td>
                )}
                {row.cells.map((cell: any) => {
                  return (
                    <td {...cell.getCellProps()} className="post-table-td">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {props.withFooter && (
          <tfoot>
            {footerGroups.map((group: any) => (
              <tr {...group.getFooterGroupProps()} className="post-table-tr">
                {group.headers.map((column: any) => (
                  <td
                    {...column.getFooterProps()}
                    colSpan={column.footerColspan ? column.footerColspan : 1}
                  >
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default ReactTable;
