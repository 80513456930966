import React from "react";
import InputBase from "../base";
import { BaseProps } from "views/components/props";
import Form, { RenderingFieldContext } from "../../Form";

interface Props extends BaseProps {
  label?: string;
  placeholder?: string;
  cols?: number;
  rows?: number;
  name?: string;
  maxLength?: number;
  isDisabled?: boolean;
  value?: any;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  onChange?: (value: string) => any;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const disabledInputStyle = {
  border: "solid 1px #757575",
  backgroundColor: "#e0e0e0",
  width:'100%',
  cursor: "pointer",
};

const InputTextArea = (props: Props) => {
  const handleOnChange = (value: string) => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <Form.Field
      name={props.name}
      render={({ field, value, onChange }: RenderingFieldContext<any>) => (
        <InputBase
          isDisabled={props.isDisabled}
          label={props.label}
          error={field.isDirty && field.error}
          suffix={props.suffix}
          prefix={props.prefix}
        >
          <textarea
            style={props.isDisabled ? disabledInputStyle : props.style}
            name={props.name}
            disabled={props.isDisabled}
            rows={props.rows}
            cols={props.cols}
            value={value ? value : ""}
            onChange={(evt) => {
              onChange(evt.target.value);
              handleOnChange(evt.target.value);
            }}
          />
        </InputBase>
      )}
    />
  );
};

export default InputTextArea;
