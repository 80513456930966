import { Dict } from 'utilities/type';
import { FeatureFlags, FeatureCheckFunc, AllFeatureCheck, FeatureCheck, SubscriptionFeatureFlags, SubscriptionFeatureChecks, CashlezFeatureFlags, CashlezFeatureChecks, ShopeeFeatureFlags, ShopeeFeatureChecks, MiscellaneousFeatureFlags, MiscellaneousFeatureChecks, ContactlessFeatureFlags, ContactlessFeatureChecks, FlexpayFeatureFlags, FlexpayFeatureChecks } from './def';

import defaultConfig from './default'
import { getCurrentEnvironment } from './env';
import { anyOf } from 'utilities/ops';

export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_PATH

export function isAnyOfTheseFeaturesEnabled(...checks: FeatureCheckFunc[]): boolean {
    const detail = getCurrentEnvironment()
    let featureFlags = detail.featureFlags
    const isValidFeatureFlag  = (c: FeatureCheckFunc) => c && c(featureFlags)
    return anyOf<FeatureCheckFunc>(...checks).validFor(isValidFeatureFlag)
}

function generateStateChange<T extends Dict<boolean>>(key: keyof FeatureCheck, subKey: (keyof T) & string): FeatureCheckFunc {
    return (f: FeatureFlags) => {
        if (!f[key]) {
            return false
        }
        return (f[key] as T)[subKey] == true
    }
}

function generateStateChanges<T extends Dict<any>, TOut extends Dict<FeatureCheckFunc>>(key: keyof FeatureCheck, ...subKeys: ((keyof T) & string)[]): TOut {
    const obj: Dict<FeatureCheckFunc> = {}
    subKeys.forEach((subKey: (string)) => {
        obj[subKey] = generateStateChange<T>(key, subKey)
    })
    return (obj as unknown) as TOut
}

function generateStateChangeAllTrue<T extends Dict<boolean>>(key: keyof (FeatureCheck)): FeatureCheckFunc {

    return (f: FeatureFlags) => {
        if (!f[key]) {
            return false
        }
        return Object.keys(f[key]).map(subKey => (f[key] as T)[subKey]).reduce((x, y) => x || y, false)
    }
}




export const featureCheck: FeatureCheck & AllFeatureCheck = {
    subscription: {
        ...generateStateChanges<SubscriptionFeatureFlags, SubscriptionFeatureChecks>(
            'subscription',
            'main',
            ...Object.keys(defaultConfig.featureFlags.subscription) as (keyof SubscriptionFeatureFlags)[]
        ),
        any: generateStateChangeAllTrue("home")
    },
    cashlez: {
        ...generateStateChanges<CashlezFeatureFlags, CashlezFeatureChecks>(
            "cashlez",
            "main",
            ...Object.keys(defaultConfig.featureFlags.cashlez) as (keyof CashlezFeatureFlags)[]
        ),
        any: generateStateChangeAllTrue("cashlez")
    },
    shopee: {
        ...generateStateChanges<ShopeeFeatureFlags, ShopeeFeatureChecks>(
            "shopee",
            "main",
            ...Object.keys(defaultConfig.featureFlags.shopee) as (keyof ShopeeFeatureFlags)[]),
        any: generateStateChangeAllTrue("shopee")
    },
    contactless: {
        ...generateStateChanges<ContactlessFeatureFlags, ContactlessFeatureChecks>(
            "contactless",
            "main",
            ...Object.keys(defaultConfig.featureFlags.contactless) as (keyof ContactlessFeatureFlags)[]),
        any: generateStateChangeAllTrue("contactless")
    },
    flexpay: {
        ...generateStateChanges<FlexpayFeatureFlags, FlexpayFeatureChecks>(
            "flexpay",
            "main",
            ...Object.keys(defaultConfig.featureFlags.flexpay) as (keyof FlexpayFeatureFlags)[]),
        any: generateStateChangeAllTrue("flexpay")
    },
    miscellaneous: {
        ...generateStateChanges<MiscellaneousFeatureFlags, MiscellaneousFeatureChecks>(
            'miscellaneous',
            'main',
            ...Object.keys(defaultConfig.featureFlags.miscellaneous) as (keyof MiscellaneousFeatureFlags)[]
        ),
        any: generateStateChangeAllTrue('miscellaneous')
    },
    any: (f: FeatureFlags) =>
        generateStateChangeAllTrue("subscription")(f)
        || generateStateChangeAllTrue("cashlez")(f)
        || generateStateChangeAllTrue("shopee")(f)
        || generateStateChangeAllTrue("contactless")(f)
        || generateStateChangeAllTrue("flexpay")(f)
}

