import React from "react";
import classNames from "classnames";
import "./style.css";
import { BaseProps } from "views/components/props";
import { CircleColor } from "views/components/const";

interface Props extends BaseProps {
  text?: string;
  color?: CircleColor;
}

const Circle: React.FC<Props> = (props) => {
  const className = classNames(
    "post-circle-text",
    "post-circle-text-bg-" + (props.color || "gray")
  );
  return <div className={className}>{props.text}</div>;
};

export default Circle;
export { Circle };
