import { BaseService } from "domains/commons/services";
import { ExtractedFileData } from "utilities/file/upload";
import { convertExcelBinaryTo2DArray } from "utilities/csv/fileTo2DArray";
import {
  DisbursementStatusBodyRequest,
  DisbursementModelForBulkupdate,
} from "domains/tokofiQRISDisbursement/models";
import { getDisbursementStatusValue } from "views/modules/TokofiQRISDisbursement/utils";

export class TokofiQRISDisbursementService extends BaseService {
  public extractDisbursementList = async (
    file: ExtractedFileData
  ): Promise<DisbursementModelForBulkupdate> => {
    const jsonData = convertExcelBinaryTo2DArray(file.binary);
    const disbursementList: DisbursementStatusBodyRequest[] = [];

    jsonData.list.forEach((x: any) => {
      disbursementList.push({
        transactionId: x["Transaction ID"]
          ? String(x["Transaction ID"]).replace(/\s+/g, " ")
          : "",
        paymentId: x["Payment ID"]
          ? String(x["Payment ID"]).replace(/\s+/g, " ")
          : "",
        disbursementStatus: x["Disbursement Status"]
          ? getDisbursementStatusValue(
              String(x["Disbursement Status"]).replace(/\s+/g, " ")
            )
          : "",
        notes: x["Notes"] || x["notes"] || "",
      } as DisbursementStatusBodyRequest);
    });

    return {
      disbursementList,
      fileInfo: file,
    };
  };
}
