import { runSafely } from "utilities/func"

export interface Deferred<T> {
  resolve(obj: T): void;
  promise: Promise<T>
  reject(obj?: any): void;
  isFulfilled: boolean;
  isPending: boolean;
  isRejected: boolean;
}

export function defer<T>(): Deferred<T> {

  const deferred: Deferred<T> = {
    resolve: (obj: T) => { },
    reject: (obj?: any) => { },
    promise: {} as Promise<T>,
    isFulfilled: false,
    isPending: true,
    isRejected: false

  }
  deferred.promise = new Promise<T>((res, rej) => {
    deferred.resolve = (obj: T) => {
      if (deferred.isPending && !deferred.isFulfilled && !deferred.isRejected) {
        res(obj)
      }
    }
    deferred.reject = (e: any) => {
      if (deferred.isPending && !deferred.isFulfilled && !deferred.isRejected) {
        rej(e)
      }
    }
  }).then((x) => {
    deferred.isFulfilled = true;
    deferred.isPending = false;
    return x;
  },
    (e) => {
      deferred.isRejected = true;
      deferred.isPending = false;
      throw e;
    }
  )
  return deferred
}


export function runAsync(func: () => Promise<any>): void {
  func()
}

export function runSafelyFor<T>(func: () => Promise<any>, timeout: number): Promise<T>  {
  return runSafely(async () => awaitFor(func, timeout))
}

export function awaitFor<T>(func: () => Promise<T>, timeout: number): Promise<T> {
  const deferred = defer<T>()

  setTimeout(() => {
    deferred.reject()
  }, timeout)

  func().then((res: T) => {
    deferred.resolve(res)
  }).catch(e => {
    deferred.reject()
  })

  return deferred.promise

}