import { DropdownItem } from "views/components/Field/Dropdown";

export const disbursementStatusDDItems: DropdownItem[] = [
  {
    label: "Semua Status",
    value: "",
  },
  {
    label: "Menunggu",
    value: "waiting",
  },
  {
    label: "Diproses",
    value: "on_process",
  },
  {
    label: "Selesai",
    value: "completed",
  },
  {
    label: "Gagal",
    value: "failed",
  },
  {
    label: "Batal",
    value: "cancelled",
  },
];

export const getDisbursementStatusName = (disbursementStatusValue: string) => {
  let disbursementStatusName = "";
  disbursementStatusDDItems.forEach((item) => {
    if (item.value === disbursementStatusValue) {
      disbursementStatusName = item.label;
      return;
    }
  });
  return disbursementStatusName;
};

export const getDisbursementStatusValue = (disbursementStatusName: string) => {
  let disbursementStatusValue = "";
  disbursementStatusDDItems.forEach((item) => {
    if (item.label.toLowerCase() === disbursementStatusName.toLowerCase()) {
      disbursementStatusValue = item.value;
      return;
    }
  });
  return disbursementStatusValue;
};
