import React from "react";
import "./style.css";

interface FazzLoaderProps {
  position?: "absolute" | "relative" | "fixed";
}

const Loader: React.FC<FazzLoaderProps> = (props) => {
  return (
    <React.Fragment>
      <div className={"post-loader " + (props.position || "absolute")}>
        <div className="post-loader-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Loader;
