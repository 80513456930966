import { BaseStorage } from "storages/base";
import { Dict } from "utilities/type";
import { SET_PAYMENT_METHOD_KYC_DICT, PUSH_PAYMENT_METHOD_KYC, SET_PAYMENT_METHOD_KYC_LIST_REACH_BOTTOM, SET_LOADING_MORE_PAYMENT_METHOD_KYC, SET_GETTING_PAYMENT_METHOD_KYC_LIST_REQUEST } from "./actions";
import { PaymentMethodKYCForm, GettingPaymentMethodKYCRequest } from "domains/paymentMethodKYC/models";
import { IPaymentMethodKYCStorage } from "domains/paymentMethodKYC/contracts/storage";

export class PaymentMethodKYCStorage extends BaseStorage implements IPaymentMethodKYCStorage {
  setPaymentMethodKYCFormDict(paymentMethodKYCFormDict: Dict<PaymentMethodKYCForm>): void {
    this.dispatch(SET_PAYMENT_METHOD_KYC_DICT, paymentMethodKYCFormDict);
  }

  pushPaymentMethodKYCList(paymentMethodKYCDict: Dict<PaymentMethodKYCForm>) {
    this.dispatch(PUSH_PAYMENT_METHOD_KYC, paymentMethodKYCDict);
  };

  setListReachBottom(hasReachedBottom: boolean) {
    this.dispatch(SET_PAYMENT_METHOD_KYC_LIST_REACH_BOTTOM, hasReachedBottom);
  }

  setLoadingMore(isLoadingMore: boolean) {
    this.dispatch(SET_LOADING_MORE_PAYMENT_METHOD_KYC, isLoadingMore);
  }

  setGettingListRequest(request: GettingPaymentMethodKYCRequest) {
    this.dispatch(SET_GETTING_PAYMENT_METHOD_KYC_LIST_REQUEST, request);
  }
}
