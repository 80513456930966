import { BaseService } from "domains/commons/services";
import {
  FlexpayBankModel,
  FlexpayInvoiceModel,
  FlexpayUserModel,
  GetFlexpayListParams,
  UpdateInvoicePayload,
  FlexpayCreateVendorModel,
  FlexpayRepaymentModel,
  FlexpayBillModel,
  NewFlexpayUserModel,
  FlexpayUserBillingModel,
  FlexpayUserLoanModel,
  FlexpayCreateRepaymentPayload,
} from "../model";

export interface IFlexpayService {
  getInvoiceList: (
    queryParam?: GetFlexpayListParams
  ) => Promise<FlexpayInvoiceModel[]>;
  getInvoiceListById: (id: string) => Promise<FlexpayInvoiceModel>;
  updateInvoiceStatus: (
    payload: UpdateInvoicePayload
  ) => Promise<FlexpayInvoiceModel[]>;
  getVendorList: (
    queryParam?: GetFlexpayListParams
  ) => Promise<FlexpayBankModel[]>;
  createVendor: (payload?: FlexpayCreateVendorModel) => Promise<any>;
  approveVendor: (id: string) => Promise<void>;
  rejectVendor: (id: string) => Promise<void>;
  deleteVendor: (id: string) => Promise<void>;
  getPaymentList: (queryParam?: GetFlexpayListParams) => Promise<any>;
  getPaymentListById: (id: string) => Promise<any>;
  createPayment: (payload: any) => Promise<void>;
  updatePaymentStatus: (payload: any) => Promise<any>;
  getUserList: (
    queryParam?: GetFlexpayListParams
  ) => Promise<FlexpayUserModel[]>;
  getUserDetail: (userId: string) => Promise<NewFlexpayUserModel>;
  updateToSyncFazzBiz: (userId: string) => Promise<any>;
  getBalanceHistoryImpDetail: (id: string, payload: any) => Promise<any>;
  updateUser: (payload: any) => Promise<FlexpayUserModel>;
  createBillingProfile: (payload: any) => Promise<FlexpayUserBillingModel>;
  updateBillingProfile: (payload: any) => Promise<FlexpayUserBillingModel>;
  createLoanProfile: (payload: any) => Promise<FlexpayUserLoanModel>;
  getBusinessCategory: () => Promise<any>;
  getRepaymentList: (queryParam?: any) => Promise<FlexpayRepaymentModel[]>;
  getRepaymentDetail: (repaymentId: string) => Promise<any>;
  getRepaymentHistory: (repaymentId: string) => Promise<any>;
  getBillDetailDaily: (repaymentId: string) => Promise<any>;
  getBillDaily: (queryParam?: any) => Promise<any>;
  createRepayment: (payload: FlexpayCreateRepaymentPayload) => Promise<void>;
  billForRepayment: (queryParam?: any) => Promise<any>;
  updateRepayment: (payload: any) => Promise<void>;
  deleteRepayment: (repaymentId: string) => Promise<any>;
  getBillList: (queryParam?: any) => Promise<FlexpayBillModel[]>;
  getBillDetail: (billId: string) => Promise<any>;
  getBillSendEmail: (billId: string) => Promise<any>;
  getBillDetailDownload: (billId: string) => Promise<any>;
  getActiveBill: (userId: string) => Promise<any>;
  editBillDaily: (billId: string, payload: any) => Promise<any>;
  addPaymentToBill: (paymentIds: string[], userId: string) => Promise<any>;
  updateBill: (payload: any) => Promise<any>;
  resetBill: (billId: string) => Promise<any>;
}

export class FlexpayService extends BaseService implements IFlexpayService {
  
  public async editBillDaily(billId: any, payload: any) {
    return await this.api.flexpay.editBillDaily(billId, payload);
  }

  public async getInvoiceList(queryParam?: GetFlexpayListParams) {
    return await this.api.flexpay.getInvoiceList(queryParam);
  }

  public async getInvoiceListById(id: string) {
    return await this.api.flexpay.getInvoiceListById(id);
  }

  public async updateInvoiceStatus(payload: UpdateInvoicePayload) {
    return await this.api.flexpay.updateInvoiceStatus(payload);
  }

  public async getVendorList(queryParam?: GetFlexpayListParams) {
    return await this.api.flexpay.getVendorList(queryParam);
  }

  public async approveVendor(id: string) {
    return await this.api.flexpay.approveVendor(id);
  }

  public async rejectVendor(id: string) {
    return await this.api.flexpay.rejectVendor(id);
  }

  public async deleteVendor(id: string) {
    return await this.api.flexpay.deleteVendor(id);
  }

  public async getPaymentList(queryParam?: GetFlexpayListParams) {
    return await this.api.flexpay.getPaymentList(queryParam);
  }

  public async getPaymentListById(id: string) {
    return await this.api.flexpay.getPaymentListById(id);
  }

  public async createPayment(payload: any) {
    return await this.api.flexpay.createPayment(payload);
  }

  public async updatePaymentStatus(payload: any) {
    return await this.api.flexpay.updatePaymentStatus(payload);
  }

  public async getUserList(queryParam?: GetFlexpayListParams) {
    return await this.api.flexpay.getUserList(queryParam);
  }

  public async getUserDetail(userId: string) {
    return await this.api.flexpay.getUserDetail(userId);
  }

  public async updateToSyncFazzBiz(userId: string) {
    return await this.api.flexpay.updateToSyncFazzBiz(userId);
  }

  public async getBalanceHistoryImpDetail(id: string, payload: any) {
    return await this.api.flexpay.getBalanceHistoryImpDetail(id, payload);
  }

  public async updateUser(payload: any) {
    return await this.api.flexpay.updateUser(payload);
  }

  public async updateBillingProfile(payload: any) {
    return await this.api.flexpay.updateBillingProfile(payload);
  }

  public async createBillingProfile(payload: any) {
    return await this.api.flexpay.createBillingProfile(payload);
  }

  public async createLoanProfile(payload: any) {
    return await this.api.flexpay.createLoanProfile(payload);
  }

  public async createVendor(payload: FlexpayCreateVendorModel) {
    return await this.api.flexpay.createVendor(payload);
  }

  public async getBusinessCategory() {
    return await this.api.flexpay.getBusinessCategory();
  }

  public async getRepaymentList(queryParam?: GetFlexpayListParams) {
    return await this.api.flexpay.getRepaymentList(queryParam);
  }

  public async getRepaymentDetail(repaymentId: string) {
    return await this.api.flexpay.getRepaymentDetail(repaymentId);
  }
  public async getRepaymentHistory(repaymentId: string) {
    return await this.api.flexpay.getRepaymentHistory(repaymentId);
  }

  public async getBillDaily(queryParam?: any) {
    return await this.api.flexpay.getBillDaily(queryParam);
  }

  public async getBillDetailDaily(repaymentId: string) {
    return await this.api.flexpay.getBillDetailDaily(repaymentId);
  }



  public async getBillSendEmail(billId: string) {
    return await this.api.flexpay.getBillSendEmail(billId);
  }

  public async createRepayment(payload: FlexpayCreateRepaymentPayload) {
    return await this.api.flexpay.createRepayment(payload);
  }

  public async billForRepayment(queryParam: any) {
    return await this.api.flexpay.billForRepayment(queryParam);
  }

  public async updateRepayment(payload: any) {
    return await this.api.flexpay.updateRepayment(payload);
  }

  public async deleteRepayment(repaymentId: string) {
    return await this.api.flexpay.deleteRepayment(repaymentId);
  }

  public async getBillList(queryParam?: GetFlexpayListParams) {
    return await this.api.flexpay.getBillList(queryParam);
  }

  public async getBillDetail(billId: string) {
    return await this.api.flexpay.getBillDetail(billId);
  }

  public async getBillDetailDownload(billId: string) {
    return await this.api.flexpay.getBillDetailDownload(billId);
  }

  public async getActiveBill(userId: string) {
    return await this.api.flexpay.getActiveBill(userId);
  }

  public async addPaymentToBill(paymentIds: string[], userId: string) {
    return await this.api.flexpay.addPaymentToBill(paymentIds, userId);
  }

  public async updateBill(payload: any) {
    return await this.api.flexpay.updateBill(payload);
  }

  public async resetBill(billId: string) {
    return await this.api.flexpay.resetBill(billId);
  }
}
