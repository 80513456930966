
import React from 'react'




export interface TabContext<T extends object=any> {
    currentTabKey?:string
    registerTab: (key: string, name: string) => any
}

export default React.createContext<TabContext<any>>({
    registerTab: (key: string, name: string) => {}
});
