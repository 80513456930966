import { Reducer } from "utilities/redux/reducer";
import { Dict } from "utilities/type";
import { ShopeeOrderState } from "domains/shopeeOrder/states";
import { SET_SHOPEE_ORDER, PUSH_SHOPEE_ORDER, SET_SHOPEE_ORDER_LIST_REACH_BOTTOM, SET_LOADING_MORE_SHOPEE_ORDER, SET_GETTING_SHOPEE_ORDER_LIST_REQUEST, SET_SHOPEE_ORDER_SUMMARY } from "./actions";
import { ShopeeOrderModel, GettingShopeeOrderRequest, ShopeeOrderSummary } from "domains/shopeeOrder/models";

export default class ShopeeOrderReducer extends Reducer<ShopeeOrderState> {
  constructor() {
    super({
      shopeeOrders: {},
      shopeeOrderSummaries: {},
      shopeeOrderListManager: {
        isLoadingMore: false,
        request: {},
        hasReachedBottom: false
      },
    })
  }

  public setShopeeOrders(state: ShopeeOrderState, shopeeOrders: Dict<ShopeeOrderModel>): ShopeeOrderState {
    return {
      ...state,
      shopeeOrders
    }
  }

  public setShopeeOrderSummaries(state: ShopeeOrderState, shopeeOrderSummaries: Dict<ShopeeOrderSummary>): ShopeeOrderState {
    return {
      ...state,
      shopeeOrderSummaries
    }
  }

  public pushShopeeOrderList(state: ShopeeOrderState, shopeeOrders: Dict<ShopeeOrderModel>) {
    return {
      ...state,
      shopeeOrders: {
        ...state.shopeeOrders,
        ...shopeeOrders
      }
    };
  };

  public setListReachBottom(state: ShopeeOrderState, hasReachedBottom: boolean) {
    return {
      ...state,
      shopeeOrderListManager: {
        ...state.shopeeOrderListManager,
        hasReachedBottom
      }
    }
  }

  public setLoadingMore(state: ShopeeOrderState, isLoadingMore: boolean) {
    return {
      ...state,
      shopeeOrderListManager: {
        ...state.shopeeOrderListManager,
        isLoadingMore
      }
    }
  }


  public setGettingListRequest(state: ShopeeOrderState, request: GettingShopeeOrderRequest) {
    return {
      ...state,
      shopeeOrderListManager: {
        ...state.shopeeOrderListManager,
        request
      }
    }
  }

  get actions(): {
    [p: string]: (state: ShopeeOrderState, payload?: any) => any;
  } {
    return {
      [SET_SHOPEE_ORDER]: this.setShopeeOrders,
      [SET_SHOPEE_ORDER_SUMMARY]: this.setShopeeOrderSummaries,
      [PUSH_SHOPEE_ORDER]: this.pushShopeeOrderList,
      [SET_SHOPEE_ORDER_LIST_REACH_BOTTOM]: this.setListReachBottom,
      [SET_LOADING_MORE_SHOPEE_ORDER]: this.setLoadingMore,
      [SET_GETTING_SHOPEE_ORDER_LIST_REQUEST]: this.setGettingListRequest
    };
  }
}
