import { BaseApi } from "api/base.api";
import { IContactlessApi } from "domains/contactless/contract/api";
import { ContactlessMerchant, ContactlessMerchantDetail, ContactlessMerchantStatus, GetMerchantQueryParams } from "domains/contactless/model";

export default class ContactlessApi extends BaseApi implements IContactlessApi {
  constructor() {
    super('/admin/v2')
  }
  
  public getMerchantList(queryParams: GetMerchantQueryParams): Promise<ContactlessMerchant[]> {
    return this.makeForCamelCaseForList('GET', '/merchants', queryParams)
  }

  public getMerchantDetail(userId: string): Promise<ContactlessMerchantDetail> {
    return this.makeForCamelCase('GET', `/merchants/${userId}`)
  }

  public suspendMerchant(userId: string): Promise<void> {
    return this.makeForCamelCase('POST', `/merchants/${userId}/suspend`)
  }

  public getMerchantStatus(userId: string): Promise<ContactlessMerchantStatus> {
    return this.makeForCamelCase('GET', `/merchants/${userId}/status`)
  }
}
