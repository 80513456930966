import { BaseStorage } from "storages/base";
import { IVoucherStorage } from '../../domains/voucher/contracts/storage';
import { SET_VOUCHER, PUSH_VOUCHER, SET_VOUCHER_LIST_REACH_BOTTOM, SET_LOADING_MORE_VOUCHER, SET_GETTING_VOUCHER_LIST_REQUEST, SET_VOUCHER_RULES } from "./actions";
import { VoucherModel, GettingVouchersRequest, VoucherRuleModel } from "domains/voucher/models";
import { Dict } from "utilities/type";


export class VoucherStorage extends BaseStorage implements IVoucherStorage {
  setVouchers(vouchers: Dict<VoucherModel>): void {
    this.dispatch(SET_VOUCHER, vouchers);
  }

  setVoucherRules(vouchers: Dict<VoucherRuleModel>): void {
    this.dispatch(SET_VOUCHER_RULES, vouchers);
  }

  public pushVouchers(voucherDict: Dict<VoucherModel>): void {
    this.dispatch(PUSH_VOUCHER, voucherDict)
  }

  public setVouchersReachBottom(hasReachedBottom: boolean): void {
    this.dispatch(SET_VOUCHER_LIST_REACH_BOTTOM, hasReachedBottom)
  }

  public setLoadingMoreVouchers(isLoadingMoreVoucher: boolean): void {
    this.dispatch(SET_LOADING_MORE_VOUCHER, isLoadingMoreVoucher)
  }
  public setGettingVoucherListRequest(request: GettingVouchersRequest): void {
    this.dispatch(SET_GETTING_VOUCHER_LIST_REQUEST, request)
  }
}
