import React, { useEffect, useState } from "react";
import "./style.scss";
import { Text, Icon } from "..";
import { RouteDescription } from "utilities/router/route.item";
import { history } from "storages/store";
import { APP_ROUTE_DESCRIPTIONS } from "routes/app-routes";
import { GoogleLogout } from "react-google-login";
import {
  featureCheck,
  getAppConfig,
  isAnyOfTheseFeaturesEnabled,
} from "config";
import { authServices } from "injectors";

interface Props {
  isUserAdmin: boolean;
  isUserFinance: boolean;
}

const renderMenu = (
  route: RouteDescription,
  isShowChildMenu: any,
  actionShowHideShowMenu: any
) => {
  const matchRoute = history.location.pathname.includes(route.path);
  const matchRouteChildren = history.location.pathname.includes(route.path);

  console.log(route);

  return (
    route.text &&
    (!route.childrenDescription ? (
      <div
        className="item"
        key={route.key}
        onClick={() => history.push(route.path)}
      >
        {route.icon}
        <Text.Span
          size={14}
          align="left"
          color={matchRoute ? "white" : "semiGray"}
        >
          {route.text}
        </Text.Span>
      </div>
    ) : (
      <div className="item" key={route.key}>
        {route.icon}
        <Text.Span
          size={14}
          onClick={() => actionShowHideShowMenu()}
          align="left"
          color={matchRouteChildren ? "white" : "semiGray"}
        >
          {route.text}
        </Text.Span>
        {isShowChildMenu ? (
          <Icon.ChevronDown className="chevron-down" />
        ) : (
          <Icon.ChevronRight className="float-icon" />
        )}
        {isShowChildMenu && (
          <div>
            {route.childrenDescription.map((list, index) => {
              return (
                <Text.Span
                  key={index}
                  size={14}
                  style={{ display: "block", marginTop: 10 }}
                  onClick={() => history.push(list.path)}
                  color={
                    history.location.pathname === list.path
                      ? "white"
                      : "semiGray"
                  }
                >
                  {list.text}
                </Text.Span>
              );
            })}
          </div>
        )}
      </div>
    ))
  );
};

const Sidebar: React.FC<Props> = (props: Props) => {
  const [isShowChildMenu, setIsShowChildMenu] = useState(false);

  const actionShowHideShowMenu = () => {
    setIsShowChildMenu(!isShowChildMenu);
  };
  const getUserRole = (): string => {
    if (props.isUserAdmin && props.isUserFinance) {
      return "superAdmin";
    }
    if (props.isUserAdmin) {
      return "admin";
    }
    if (props.isUserFinance) {
      return "finance";
    }
    return "";
  };

  const adminMenuList = [
    "subscription",
    "voucher",
    "cashlezKYC",
    "cashlezKYCDetail",
    "shopeePayKYC",
    "shopeePayKYCDetail",
    "shopeeOrder",
    "contactless",
    "flexpayInvoice",
    "flexpayInvoiceDetail",
    "flexpayPayment",
    "flexpayPaymentDetail",
    "flexpayVendor",
    "flexpayVendorDetail",
    "flexpayVendorCreate",
    "flexpayUser",
    "flexpayUserDetail",
    "flexpayUserDetailWallet",
    "flexpayPaymentCreation",
    "flexpayBillPayment",
    "flexpayBillDailyTable",
    "flexpayBillDailyDetail",
    "flexpayBillSummary",
    "flexpayBillCreate",
    "flexpayBillPaymentDetail",
    "flexpayRepayment",
    "flexpayRepaymentDetail",
    "flexpayRepaymentCreate",
    "flexpayAccumulativeAmountDue"
  ];
  const financeMenuList = [
    "shopeeOrder",
    "disbursement",
    "tokofiQRISDisbursement",
    "tokofiQRISDisbursementBulkUpdate",
  ];

  const getMenuList = () => {
    const userRole = getUserRole();
    switch (userRole) {
      case "superAdmin":
        return [...adminMenuList, ...financeMenuList];
      case "admin":
        return adminMenuList;
      case "finance":
        return financeMenuList;
      default:
        return [];
    }
  };

  let appRoute = APP_ROUTE_DESCRIPTIONS;

  if (isAnyOfTheseFeaturesEnabled(featureCheck.miscellaneous.role)) {
    appRoute = APP_ROUTE_DESCRIPTIONS.filter((a) =>
      getMenuList().includes(a.key)
    );
  }

  useEffect(() => {
    if (isAnyOfTheseFeaturesEnabled(featureCheck.miscellaneous.role)) {
      const listPathName = appRoute.map((r) => r.path);
      if (!listPathName.includes(history.location.pathname)) {
        // history.push(listPathName[0]);
      }
    }
  }, []);

  const logout = async (googleLogout: () => void) => {
    try {
      googleLogout();
      await authServices.logout();
    } catch (error) {
      console.error(error);
    }
  };

  console.log(appRoute);
  return (
    <div className={"sidebar main-side"}>
      <div className="post-sidebar">
        <div className="post-sidebar-logo">
          <img src="/assets/Img/post-logo-white.png" width="88px" alt="logo" />
        </div>
        <div className="post-menu">
          {appRoute.map(
            (route: RouteDescription) =>
              !route.hideSidebar &&
              renderMenu(route, isShowChildMenu, actionShowHideShowMenu)
          )}
          <GoogleLogout
            clientId={getAppConfig().detail.googleClientId}
            buttonText="Logout"
            render={(props) => (
              <div
                key="Logout"
                onClick={() => logout(props.onClick)}
                style={{
                  position: "absolute",
                  bottom: "16px",
                  left: "24px",
                  cursor: "pointer",
                }}
              >
                <Icon.Logout />
                <Text.Span size={14} align="left" color="white">
                  Keluar
                </Text.Span>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
