import { BaseApi } from "api/base.api";
import { getAppConfig } from "config";
import { IFlexpayApi } from "domains/flexpay/contract/api";
import {
  FlexpayBankModel,
  FlexpayBillModel,
  FlexpayCreateVendorModel,
  FlexpayInvoiceModel,
  FlexpayRepaymentModel,
  FlexpayUserLoanModel,
  FlexpayUserModel,
  GetFlexpayListParams,
  NewFlexpayUserModel,
  UpdateInvoicePayload,
} from "domains/flexpay/model";

export default class FlexpayApi extends BaseApi implements IFlexpayApi {
  constructor() {
    const { flexpayApiUrl } = getAppConfig();
    super(`${flexpayApiUrl}`);
  }

  public async getInvoiceList(
    queryParams: GetFlexpayListParams
  ): Promise<FlexpayInvoiceModel[]> {
    return await this.makeForCamelCase(
      "GET",
      "/admin/expense/v1/all",
      queryParams
    );
  }

  public async getInvoiceListById(id: string): Promise<FlexpayInvoiceModel> {
    return await this.makeForCamelCase("GET", `/admin/expense/v1/${id}`);
  }

  public async updateInvoiceStatus(
    payload: UpdateInvoicePayload
  ): Promise<FlexpayInvoiceModel[]> {
    return await this.makeForCamelCase("POST", `/admin/expense/v1`, payload);
  }

  public async getVendorList(
    queryParams: GetFlexpayListParams
  ): Promise<FlexpayBankModel[]> {
    return await this.makeForCamelCase(
      "GET",
      "/admin/vendor/v1/all",
      queryParams
    );
  }

  public async approveVendor(id: string): Promise<any> {
    return await this.makeForCamelCase(
      "POST",
      `/admin/vendor/v1/${id}/approved`
    );
  }

  public async rejectVendor(id: string): Promise<any> {
    return await this.makeForCamelCase("POST", `/admin/vendor/v1/${id}/reject`);
  }

  public async deleteVendor(id: string): Promise<any> {
    return await this.makeForCamelCase("DELETE", `/admin/vendor/v1/${id}`);
  }

  public async getPaymentList(queryParams: GetFlexpayListParams): Promise<any> {
    return await this.makeForCamelCase("GET", `/admin/payment/v1`, queryParams);
  }

  public async getPaymentListById(id: string): Promise<any> {
    return await this.makeForCamelCase("GET", `/admin/payment/v1/${id}`);
  }

  public async createPayment(payload: any): Promise<any> {
    return await this.makeForCamelCase("POST", `/admin/payment/v1`, payload);
  }

  public async updatePaymentStatus(payload: any): Promise<any> {
    return await this.makeForCamelCase(
      "POST",
      `/admin/payment/v1/update`,
      payload
    );
  }

  public async getUserList(
    queryParams: GetFlexpayListParams
  ): Promise<FlexpayUserModel[]> {
    return await this.makeForCamelCase(
      "GET",
      "/admin/user/v1/all",
      queryParams
    );
  }

  public async getUserDetail(userId: string): Promise<NewFlexpayUserModel> {
    return await this.makeForCamelCase("GET", `/admin/user/v1/${userId}`);
  }

  public async getBalanceHistoryImpDetail(
    id: string,
    payload: any
  ): Promise<any> {
    return await this.makeForCamelCase(
      "GET",
      `/admin/user/v1/${id}/balance_history`,
      payload
    );
  }

  public async updateToSyncFazzBiz(userId: string): Promise<any> {
    return await this.makeForCamelCase(
      "POST",
      `/admin/user/v1/${userId}/integrate`
    );
  }

  public async updateUser(payload: any): Promise<FlexpayUserModel> {
    return await this.makeForCamelCase("POST", "/admin/user/v1", payload);
  }

  public async updateBillingProfile(payload: any): Promise<FlexpayUserModel> {
    return await this.makeForCamelCase(
      "POST",
      "/admin/billingprofile/v1/update",
      payload
    );
  }

  public async createBillingProfile(payload: any): Promise<FlexpayUserModel> {
    return await this.makeForCamelCase(
      "POST",
      "/admin/billingprofile/v1",
      payload
    );
  }

  public async createLoanProfile(payload: any): Promise<FlexpayUserLoanModel> {
    return await this.makeForCamelCase(
      "POST",
      "/admin/loanprofile/v1",
      payload
    );
  }

  public async createVendor(payload?: FlexpayCreateVendorModel): Promise<any> {
    return await this.makeForCamelCase("POST", "/admin/vendor/v1", payload);
  }

  public async getBusinessCategory(): Promise<any> {
    return await this.makeForCamelCase("GET", "/business_category/v1");
  }

  public async getRepaymentList(
    queryParams: GetFlexpayListParams
  ): Promise<FlexpayRepaymentModel[]> {
    return await this.makeForCamelCase(
      "GET",
      "/admin/repayment/v1/repayment/all",
      queryParams
    );
  }

  public async getRepaymentDetail(repaymentId: string): Promise<any> {
    return await this.makeForCamelCase(
      "GET",
      `/admin/repayment/v1/repayment/${repaymentId}`
    );
  }

  public async getRepaymentHistory(repaymentId: string): Promise<any> {
    return await this.makeForCamelCase(
      "GET",
      `/admin/bill/v1/daily/${repaymentId}/histories`
    );
  }

  public async getBillDaily(queryParam: any): Promise<any> {
    return await this.makeForCamelCase(
      "GET",
      `/admin/bill/v1/daily`,
      queryParam
    );
  }

  public async getBillDetailDaily(repaymentId: string): Promise<any> {
    return await this.makeForCamelCase(
      "GET",
      `/admin/bill/v1/daily/${repaymentId}`
    );
  }

  public async createRepayment(payload: any): Promise<any> {
    return await this.makeForCamelCase(
      "POST",
      "/admin/repayment/v1/repayment",
      payload
    );
  }

  public async billForRepayment(queryParams: any): Promise<any> {
    return await this.makeForCamelCase(
      "GET",
      `/admin/bill/v1/for-repayment/${queryParams}`
    );
  }

  public async updateRepayment(payload: any): Promise<any> {
    return await this.makeForCamelCase(
      "POST",
      "/admin/repayment/v1/repayment/update",
      payload
    );
  }

  public async deleteRepayment(repaymentId: string): Promise<any> {
    return await this.makeForCamelCase(
      "POST",
      `/admin/repayment/v1/repayment/${repaymentId}/delete`
    );
  }

  public async getBillList(
    queryParams: GetFlexpayListParams
  ): Promise<FlexpayBillModel[]> {
    return await this.makeForCamelCase(
      "GET",
      "/admin/bill/v1/all",
      queryParams
    );
  }

  public async getBillDetail(billId: string): Promise<any> {
    return await this.makeForCamelCase("GET", `/admin/bill/v1/${billId}`);
  }

  public async getBillSendEmail(billId: string): Promise<any> {
    return await this.makeForCamelCase("POST", `/admin/bill/v1/${billId}/send`);
  }

  public async getBillDetailDownload(billId: string): Promise<any> {
    return await this.makeForCamelCase(
      "GET",
      `/admin/bill/v1/${billId}/invoice`,
      {Accept: 'application/pdf'}
    );
  }

  public async editBillDaily(bill_id: string, payload: any): Promise<any> {
    return await this.makeForCamelCase(
      "PUT",
      `/admin/bill/v1/daily/${bill_id}/fees`,
      payload
    );
  }

  public async getActiveBill(userId: string): Promise<any> {
    return await this.makeForCamelCase(
      "GET",
      `/admin/bill/v1/${userId}/active`
    );
  }

  public async addPaymentToBill(
    paymentIds: string[],
    userId: string
  ): Promise<any> {
    return await this.makeForCamelCase("POST", `/admin/bill/v1/add-payment`, {
      paymentIds,
      userId,
    });
  }

  public async updateBill(payload: any): Promise<any> {
    return await this.makeForCamelCase("PUT", `/admin/bill/v1`, payload);
  }

  public async resetBill(billId: string): Promise<any> {
    return await this.makeForCamelCase("POST", `/admin/bill/v1/reset`, {
      bill_id: billId,
    });
  }
}
