import { reducerGenerators } from './generator'
import { Dict } from 'utilities/type'

export abstract class Reducer<T> {
  public readonly initialState: T

  constructor(state: T){
    this.initialState = state
  }

  public build() : SingleReducer<T>{
    return reducerGenerators<T>(this.initialState, this.actions)
  }

  public abstract get actions():StateActions<T>
}

export type SingleReducer<T> = (state: T, payload?: any) => T

export type StateActions<State> = Dict<SingleReducer<State>>