import { Redirect, Switch } from 'react-router-dom';
import React, { PureComponent } from 'react';
import { hot } from 'react-hot-loader/root';
import { routeFactory } from 'utilities/router/route.factory';
import { RouteItem } from 'utilities/router/route.item';
import { getLocalStorage } from 'utilities/localStorage';
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage';

class SwitchRoute extends PureComponent<{
  routes: RouteItem[]
}> {
  render() {
    return (
      <Switch>
        {routeFactory(this.props.routes)}
        <Redirect to={!getLocalStorage(CONFIG_LOCALSTORAGE.POST_TOKEN) ? '/auth/login' : '/'} />
      </Switch>
    )
  }
}

export default hot(SwitchRoute);
