import * as React from "react";
import classNames from "classnames";
import { BaseProps, BaseParentProps } from "../props";
import "./style.scss";
import { AlignmentClass } from "../const";

interface FixProps extends BaseParentProps, BaseProps {
  minWidth?: number;
  padding?: 0 | 8 | 16 | 24 | 32 | 40 | 64;
  verticalAlign?: "center" | "flex-start" | "flex-end";
}
const Fixed = (props: FixProps) => {
  let className = classNames(props.className || "", "post-sided-fix");
  let style: React.CSSProperties = {
    ...props.style,
    minWidth: props.minWidth,
    padding: (props.padding || 0) + "px",
    alignSelf: props.verticalAlign,
  };
  return (
    <div style={style} className={classNames(className)}>
      <React.Fragment>{props.children}</React.Fragment>
    </div>
  );
};

interface RemainingProps extends BaseParentProps, BaseProps {
  align?: "center" | "left" | "right";
  verticalAlign?: "center" | "flex-start" | "flex-end";
  padding?: 0 | 8 | 16 | 24 | 32 | 40 | 64;
}

const Remaining = (props: RemainingProps) => {
  let basicClassNames = classNames(
    props.className || "",
    "post-sided-remaining"
  );
  let style: React.CSSProperties = {
    ...props.style,
    textAlign: props.align,
    alignSelf: props.verticalAlign,
    padding: (props.padding || 0) + "px",
  };
  let remaining = (
    <section style={style} className={basicClassNames}>
      <React.Fragment>{props.children}</React.Fragment>
    </section>
  );
  return remaining;
};

interface SidedProps extends BaseParentProps, BaseProps {
  gutter?: 0 | 8 | 16 | 24 | 32 | 40 | 64;
  justify?: "center" | "bottom" | "top" | "normal";
  justifyContent?: AlignmentClass;
  style?: React.CSSProperties;
}

const Sided: React.FC<SidedProps> = (props: SidedProps) => {
  let className = classNames(
    props.className || "",
    "post-sided",
    "post-sided-gutter-" + (props.gutter != undefined ? props.gutter : 8),
    "post-sided-justify-" + (props.justify || "normal"),
    "post-sided-justify-content-" + (props.justifyContent || "left")
  );
  return (
    <div className={className} style={props.style}>
      <React.Fragment>{props.children}</React.Fragment>
    </div>
  );
};

export default {
  Sided,
  Fixed,
  Remaining,
};
