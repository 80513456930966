import React, { useEffect, useState } from "react";
import DailyCalendar from "./DailyCalendar";
import WeeklyCalendar from "./WeeklyCalendar";
import MonthlyCalendar from "./MonthlyCalendar";
import { Icon, Panel, Wrapper } from "..";

import "./style.css";
import { DateRange } from "domains/commons/models";
import moment from "moment";
import Button from "../Button";
import Card from "../Card";
import Sided from "../Sided";
import { toDateString } from "utilities/converter/date";
import InputBase from "../Field/base";
import Text from "../Text";

interface Props {
  onChange: (selectedDateRange: DateRange) => any;
  flagDateVisibilityFunc?: boolean;
  selectedDateRange?: any;
  dateVisibilityFunc?: (currentDay: Date, firstDateSelection?: Date) => any;
  mode: "complex" | "daily";
  placeholder?: string;
  value?: string;
  selectType?: "range" | "single";
  from?: any;
  to?: any;
  resetValue?: any;
  label?: string;
  handeVisible2?: boolean;
}

const PostCalendar = (props: Props) => {
  const [visible, setVisible] = useState(false);
  const [calendarType, setCalendarType] = useState("daily");
  const [selectedDateRange, setSelectedDateRange] = useState(
    props.selectedDateRange
  );
  const [dateDisplayValue, setDateDisplayValue] = useState("");
  const [selectType] = useState<string>(props.selectType || "range");

  useEffect(() => {
    if (selectType === "single" && props.to) {
      let placeholder = `${toDateString(props.to, "DD/MM/YYYY")}`;
      setDateDisplayValue(placeholder);
    }
    if (selectType === "range" && props.to && props.from) {
      let placeholder = `${
        toDateString(props.from, "DD/MM/YYYY") +
        " - " +
        toDateString(props.to, "DD/MM/YYYY")
      }`;
      setDateDisplayValue(placeholder);
    }
  }, []);

  useEffect(() => {
    if (props.resetValue) {
      if (selectType === "single" && props.to) {
        let placeholder = `${toDateString(props.to, "DD/MM/YYYY")}`;
        setDateDisplayValue(placeholder);
      }
      if (selectType === "range" && props.to && props.from) {
        let placeholder = `${
          toDateString(props.from, "DD/MM/YYYY") +
          " - " +
          toDateString(props.to, "DD/MM/YYYY")
        }`;
        setDateDisplayValue(placeholder);
      }
    }
  }, [props.resetValue]);

  useEffect(() => {
    if (
      (selectedDateRange?.from || selectedDateRange?.to) &&
      !dateDisplayValue
    ) {
      adjustDateRange(selectedDateRange);
    }
  }, [selectedDateRange, dateDisplayValue]);

  const adjustDateRange = (dateRange: DateRange) => {
    let selectedDate: DateRange = {} as DateRange;
    if (selectType === "range") {
      selectedDate = {
        from: moment(dateRange.from).startOf("day").toDate(),
        to: moment(dateRange.to).endOf("day").toDate(),
      };
    }
    if (selectType === "single") {
      selectedDate = {
        from: moment(dateRange.to).startOf("day").toDate(),
        to: moment(dateRange.to).startOf("day").toDate(),
      };
      props.onChange(selectedDate);
      setVisible(false);
    }
    setSelectedDateRange(selectedDate);
    const { from, to } = selectedDate;
    let placeholder = "";
    if (toDateString(from, "DD/MM/YYYY") === toDateString(to, "DD/MM/YYYY")) {
      placeholder = `${toDateString(from, "DD/MM/YYYY")}`;
    } else {
      placeholder = `${
        toDateString(from, "DD/MM/YYYY") +
        " - " +
        toDateString(to, "DD/MM/YYYY")
      }`;
    }
    setDateDisplayValue(placeholder);
  };

  const confirmSelectedDate = () => {
    if (!selectedDateRange) {
      return;
    }
    props.onChange(selectedDateRange);
    setVisible(false);
  };

  const handleVisibleChange = () => {
    setVisible((prevState) => !prevState);
  };

  const handleVisibleChange2 = () => {
    setVisible(false);
  };

  const handleClickDaily = () => {
    setCalendarType("daily");
  };

  const handleClickWeekly = () => {
    setCalendarType("weekly");
  };

  const handleClickMonthly = () => {
    setCalendarType("monthly");
  };

  const renderCalendar = () => {
    if (calendarType === "daily") {
      return (
        <DailyCalendar
          isActive={true}
          flagDateVisibilityFunc={props.flagDateVisibilityFunc}
          dateVisibilityFunc={props.dateVisibilityFunc}
          selectedDateRange={selectedDateRange}
          onChange={(dateRange: DateRange) => {
            adjustDateRange(dateRange);
          }}
        />
      );
    } else if (calendarType === "weekly") {
      return (
        <WeeklyCalendar
          selectedDateRange={selectedDateRange}
          onChange={(dateRange: DateRange) => adjustDateRange(dateRange)}
        />
      );
    } else {
      return (
        <MonthlyCalendar
          selectedDateRange={selectedDateRange}
          onChange={(dateRange: DateRange) => adjustDateRange(dateRange)}
        />
      );
    }
  };

  const confirmSelection = () => (
    <Wrapper>
      <Button
        theme="secondary"
        fullW={true}
        onClick={() => confirmSelectedDate()}
        label="Pilih"
      />
    </Wrapper>
  );

  const renderDateSelector = () => (
    <Sided.Sided className="mode-selector">
      <Sided.Remaining>
        <Button
          theme="primary"
          fullW={true}
          onClick={handleClickDaily}
          label="Harian"
        ></Button>
      </Sided.Remaining>
      <Sided.Remaining>
        <Button
          theme="primary"
          fullW={true}
          onClick={handleClickWeekly}
          label="Mingguan"
        ></Button>
      </Sided.Remaining>
      <Sided.Remaining>
        <Button
          theme="primary"
          fullW={true}
          onClick={handleClickMonthly}
          label="Bulanan"
        ></Button>
      </Sided.Remaining>
    </Sided.Sided>
  );

  const menu = (
    <Card type="shadow" className={"post-calendar-popup"}>
      <Panel.Panel type="rounded" className="panel">
        {props.mode === "complex" ? (
          <Panel.Header>{renderDateSelector()}</Panel.Header>
        ) : null}

        {renderCalendar()}
        {selectType !== "single" && (
          <Panel.Footer>{confirmSelection()}</Panel.Footer>
        )}
      </Panel.Panel>
    </Card>
  );

  return (
    <Wrapper
      onMouse={props.handeVisible2 === false ? null : handleVisibleChange2}
      className="post-calendar"
    >
      <Wrapper onClick={handleVisibleChange}>
        <InputBase
          className="post-calendar-inner"
          label={props.label}
          prefix={<Icon.Calendar color="black" />}
          suffix={<Icon.Caretdown color="black" />}
        >
          <React.Fragment>
            <Text.Span
              className="post-calendar-placeholder"
              size={14}
              color={
                props.value
                  ? "black"
                  : !dateDisplayValue && props.placeholder
                  ? "gray"
                  : "black"
              }
            >
              {dateDisplayValue || props.placeholder}
            </Text.Span>
          </React.Fragment>
        </InputBase>
      </Wrapper>
      {visible && menu}
    </Wrapper>
  );
};

export default PostCalendar;
