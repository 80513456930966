import { Reducer, SingleReducer } from 'utilities/redux/reducer'
import { KeyedValue } from 'utilities/type'
import { Dict } from 'utilities/type'
import CommonState, { AlertConfig } from 'domains/commons/states'
import { MODAL_LAYER_SET, MODAL_LAYER_REMOVE, MODAL_LAYER_PUSH, SET_LOADING, SET_ALERT_CONFIG } from './actions'

export class CommonReducer extends Reducer<CommonState>{
    constructor() {
      super({
        loading: {},
        visible: {},
        modalComponents: []
      })
    }
  
    public setLoading(state: CommonState, payload: KeyedValue<boolean>): CommonState {
      return {
        ...state,
        loading: {
          ...state.loading,
          [payload.key]: payload.value,
        },
      }
    }
  
    public setAlertConfig(state: CommonState, alert?: AlertConfig): CommonState {
      return {
        ...state,
        alert
      }
    }
  
  
    public setModalLayer(state: CommonState, modalComponents: any[]): CommonState {
      return {
        ...state,
        modalComponents
      }
    }
    public removeModalLayer(state: CommonState, id: string): CommonState {
  
      const modalComponents = state.modalComponents.filter(x => x.id != id)
      return {
        ...state,
        modalComponents
      }
    }
  
  
    public pushModalLayer(state: CommonState, mc: { id: string }): CommonState {
  
      return {
        ...state,
        modalComponents: [...state.modalComponents, mc]
      }
    }
  
  
  
  
    get actions(): Dict<SingleReducer<CommonState>> {
      return {
        [MODAL_LAYER_SET]: this.setModalLayer,
        [MODAL_LAYER_REMOVE]: this.removeModalLayer,
        [MODAL_LAYER_PUSH]: this.pushModalLayer,
        [SET_LOADING]: this.setLoading,
        [SET_ALERT_CONFIG]: this.setAlertConfig
      };
    }
  }