import Text from "./Text";
import TextArea from "./TextArea"
import Password from "./Password";
import Email from "./Email";
import Number from "./Number";
import Percentage from "./Percentage";
import Dropdown from "./Dropdown";
import Npwp from "./Npwp";
import Currency from "./Currency";
import Radio from "./Radio";

const Field = {
  Text,
  TextArea,
  Password,
  Email,
  Number,
  Dropdown,
  Npwp,
  Percentage,
  Currency,
  Radio
};

export default Field;
