import { Reducer } from "utilities/redux/reducer";
import { SubscriptionModel, GettingSubscriptionsRequest } from 'domains/subscription/models';
import { Dict } from "utilities/type";
import { SubscriptionState } from "domains/subscription/states";
import { SET_SUBSCRIPTION, PUSH_SUBSCRIPTION, SET_LOADING_MORE_SUBSCRIPTION, SET_SUBSCRIPTION_LIST_REACH_BOTTOM, SET_GETTING_SUBSCRIPTION_LIST_REQUEST } from "./actions";

export default class SubscriptionReducer extends Reducer<SubscriptionState> {
  constructor() {
    super({
      subscriptions: {},
      subscriptionListManager: {
        isLoadingMoreSubscription: false,
        request: {},
        hasReachedBottom: false
      },
    })
  }

  public setSubscriptions(state: SubscriptionState, subscriptions: Dict<SubscriptionModel>): SubscriptionState {
    return {
      ...state,
      subscriptions
    }
  }

  public pushSubscriptions(state: SubscriptionState, subscriptions: Dict<SubscriptionModel>): SubscriptionState {
    return {
      ...state,
      subscriptions: {
        ...state.subscriptions,
        ...subscriptions
      }
    };
  };

  public setSubscriptionListReachBottom(state: SubscriptionState, hasReachedBottom: boolean): SubscriptionState {
    return {
      ...state,
      subscriptionListManager: {
        ...state.subscriptionListManager,
        hasReachedBottom
      }
    }
  }

  public setLoadingMoreSubscriptions(state: SubscriptionState, isLoadingMoreSubscription: boolean): SubscriptionState {
    return {
      ...state,
      subscriptionListManager: {
        ...state.subscriptionListManager,
        isLoadingMoreSubscription
      }
    }
  }


  public setGettingSubscriptionListRequest(state: SubscriptionState, request: GettingSubscriptionsRequest): SubscriptionState {
    return {
      ...state,
      subscriptionListManager: {
        ...state.subscriptionListManager,
        request
      }
    }
  }

  get actions(): {
    [p: string]: (state: SubscriptionState, payload?: any) => any;
  } {
    return {
      [SET_SUBSCRIPTION]: this.setSubscriptions,
      [PUSH_SUBSCRIPTION]: this.pushSubscriptions,
      [SET_SUBSCRIPTION_LIST_REACH_BOTTOM]: this.setSubscriptionListReachBottom,
      [SET_LOADING_MORE_SUBSCRIPTION]: this.setLoadingMoreSubscriptions,
      [SET_GETTING_SUBSCRIPTION_LIST_REQUEST]: this.setGettingSubscriptionListRequest,
    };
  }
}
