import { IUserStorage } from "domains/user/contracts/storage";
import { UserModel } from "domains/user/models";
import { BaseStorage } from "storages/base";
import { SET_USER } from "./actions";

export class UserStorage extends BaseStorage implements IUserStorage {
  public setUser(user: UserModel): void {
    this.dispatch(SET_USER, user);
  }
}
