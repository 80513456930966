import React from "react";
import "./style.css";
import classNames from "classnames";
import { BaseParentProps, BaseProps } from "../props";
import { OfficialTextColor, AlignmentClass } from "../const";

interface ParagraphProps extends BaseParentProps, BaseProps {
  color?: OfficialTextColor;
  size?: 8 | 10 | 12 | 14 | 18 | 40 | 32 | 24 | 20 | 16;
  weight?: any;
  dangerousContent?: string;
  align?: AlignmentClass;
  onClick?: () => any;
  noMargin?: boolean;
  noWrap?: boolean;
}

interface HeadingProps extends BaseParentProps, BaseProps {
  color?: OfficialTextColor;
  h?: 1 | 2 | 3 | 4 | 5 | 6;
  dangerousContent?: string;
  align?: AlignmentClass;
  onClick?: () => any;
  noMargin?: boolean;
}

interface SpanProps extends BaseParentProps, BaseProps {
  color?: OfficialTextColor;
  size?: 10 | 12 | 14 | 16 | 18 | 20 | 24 | 32 | 40;
  dangerousContent?: string;
  align?: AlignmentClass;
  onClick?: () => any;
}

const Paragraph = (props: ParagraphProps) => {
  const className = classNames(
    "post-paragraph",
    props.color,
    props.size ? "post-text-size-" + props.size : "",
    props.align ? "post-align-" + props.align : "",
    props.className || "",
    { "cursor-pointer": props.onClick },
    props.noMargin ? "post-paragraph-no-margin" : ""
  );
  const style: React.CSSProperties = {
    fontSize: props.size,
    whiteSpace: props.noWrap ? "nowrap" : "normal",
    fontWeight: props.weight,
    ...props.style,
  };
  const dangerousContent = props.dangerousContent
    ? { __html: props.dangerousContent }
    : null;
  return (
    <p
      className={className}
      style={style}
      onClick={props.onClick}
      dangerouslySetInnerHTML={dangerousContent}
    >
      {props.children}
    </p>
  );
};
const Heading = (props: HeadingProps) => {
  let className = classNames(
    "post-heading",
    props.color,
    props.align ? "post-align-" + props.align : "",
    props.className || "",
    { "cursor-pointer": props.onClick },
    props.noMargin ? "post-heading-no-margin" : ""
  );
  const CustomTag = `h${props.h}` as any;
  const dangerousContent = props.dangerousContent
    ? { __html: props.dangerousContent }
    : null;

  return (
    <CustomTag
      className={className}
      onClick={props.onClick}
      dangerouslySetInnerHTML={dangerousContent}
    >
      {props.children}
    </CustomTag>
  );
};
const Span = (props: SpanProps) => {
  let className = classNames(
    "post-span",
    props.color,
    props.align ? "post-align-" + props.align : "",
    props.size ? "post-text-size-" + props.size : "",
    props.className || "",
    { "cursor-pointer": props.onClick }
  );

  const dangerousContent = props.dangerousContent
    ? { __html: props.dangerousContent }
    : null;

  const style: React.CSSProperties = {
    ...props.style,
  };

  return (
    <span
      className={className}
      onClick={props.onClick}
      dangerouslySetInnerHTML={dangerousContent}
      style={style}
    >
      {props.children}
    </span>
  );
};

const Text = {
  Paragraph,
  Heading,
  Span,
};

export default Text;
export { Paragraph, Heading, Span };
