import React from 'react'
import classNames from 'classnames';
import './style.css';

interface Props {
    onClick?: () => any;
    active?: boolean
}

const RadioButton = (props: Props) => {
  const className = classNames( 
        'post-radiobutton',
        'post-radiobutton-'+(props.active ? 'active' : 'not')
  )
  return (
    <div className={className} onClick={props.onClick} />
  )
}

export default RadioButton;
export{
    RadioButton
}
