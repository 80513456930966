import React from "react";
import InputBase from "../base";
import Form, { RenderingFieldContext } from "../../Form";
import NumberFormat from "react-number-format";

interface Props {
  label?: string;
  placeholder?: string;
  name?: string;
  isDisabled?: boolean;
  style?: any;
  onChange?: (val: number | undefined) => any;
}

const disabledInputStyle = {
  border: "solid 1px #757575",
  backgroundColor: "#e0e0e0",
  cursor: "pointer",
};

const handleKeyPress = (e: React.KeyboardEvent) => {
  if (!/[0-9]|\./.test(e.key)) {
    e.preventDefault();
  }
};

const InputCurrency = (props: Props) => {

  const handleOnChange = (val: any | undefined) => {
    if (props.onChange) {
      props.onChange(val);
    }
  };

  return (
    <Form.Field
      name={props.name}
      render={({ field, value, onChange, onBlur }: RenderingFieldContext) => (
        <InputBase
          label={props.label}
          isDisabled={props.isDisabled}
          error={field.isDirty && field.error}
        >
          <NumberFormat
            style={props.isDisabled ? disabledInputStyle : props.style}
            disabled={props.isDisabled}
            name={props.name}
            placeholder={props.placeholder}
            value={value ? value : ""}
            onBlur={onBlur}
            onKeyPress={handleKeyPress}
            onChange={(evt: any) => {
              let format = evt.target.value?.replace(/[Rp.,*+?^_${}()\s|[\]\\]/g, "")
              let parse = parseFloat(format)
              onChange(parse);
              handleOnChange(parse);
            }}
            thousandSeparator={true}
            prefix={"Rp. "}
          />
        </InputBase>
      )}
    />
  );
};

export default InputCurrency;
