import { ICommonService, CommonService } from "domains/commons/services";
import { AppStore } from "storages/store";
import { ICommonStorage } from "domains/commons/contracts/storage";
import { IAuthService, AuthService } from "domains/auth/services";
import api from "api";
import { CommonStorage } from "storages/common/storage";
import { ISubscriptionStorage } from "domains/subscription/contracts/storage";
import { SubscriptionStorage } from "storages/subscriptions/storage";
import {
  ISubscriptionService,
  SubscriptionService,
} from "domains/subscription/services";
import { IPaymentMethodKYCStorage } from "domains/paymentMethodKYC/contracts/storage";
import { PaymentMethodKYCStorage } from "storages/paymentMethodKYC/storage";
import {
  IPaymentMethodKYCService,
  PaymentMethodKYCService,
} from "domains/paymentMethodKYC/services";
import {
  IShopeeOrderService,
  ShopeeOrderService,
} from "domains/shopeeOrder/services";
import { IShopeeOrderStorage } from "domains/shopeeOrder/contracts/storage";
import { ShopeeOrderStorage } from "storages/shopeeOrder/storage";
import { IDisbursementStorage } from "domains/disbursement/contract/storage";
import { DisbursementStorage } from "storages/disbursement/storage";
import {
  DisbursementService,
  IDisbursementService,
} from "domains/disbursement/service";
import { TokofiQRISDisbursementService } from "domains/tokofiQRISDisbursement/service";
import { IUserStorage } from "domains/user/contracts/storage";
import { UserStorage } from "storages/user/storage";
import { IVoucherService, VoucherService } from "domains/voucher/services";
import { IVoucherStorage } from "domains/voucher/contracts/storage";
import { VoucherStorage } from "storages/voucher/storage";
import {
  ContactlessService,
  IContactlessService,
} from "./domains/contactless/service/index";
import { FlexpayService, IFlexpayService } from "domains/flexpay/service";
import { BankService, IBankService } from "domains/bank/service";

const commonStorage: ICommonStorage = new CommonStorage(AppStore);
const voucherStorage: IVoucherStorage = new VoucherStorage(AppStore);
const subscriptionStorage: ISubscriptionStorage = new SubscriptionStorage(
  AppStore
);
const paymentMethodKYCStorage: IPaymentMethodKYCStorage =
  new PaymentMethodKYCStorage(AppStore);
const shopeeOrderStorage: IShopeeOrderStorage = new ShopeeOrderStorage(
  AppStore
);
const disbursementStorage: IDisbursementStorage = new DisbursementStorage(
  AppStore
);
const userStorage: IUserStorage = new UserStorage(AppStore);

export const commonServices: ICommonService = new CommonService(
  commonStorage,
  api
);
export const authServices: IAuthService = new AuthService(
  commonStorage,
  api,
  userStorage
);
export const voucherService: IVoucherService = new VoucherService(
  commonStorage,
  api,
  voucherStorage
);
export const subscriptionService: ISubscriptionService =
  new SubscriptionService(commonStorage, api, subscriptionStorage);
export const paymentMethodKYCService: IPaymentMethodKYCService =
  new PaymentMethodKYCService(commonStorage, api, paymentMethodKYCStorage);
export const shopeeOrderService: IShopeeOrderService = new ShopeeOrderService(
  commonStorage,
  api,
  shopeeOrderStorage
);
export const disbursementService: IDisbursementService =
  new DisbursementService(commonStorage, api, disbursementStorage);
export const tokofiQRISDisbursementService = new TokofiQRISDisbursementService(
  commonStorage,
  api
);
export const contactlessService: IContactlessService = new ContactlessService(
  commonStorage,
  api
);
export const flexpayService: IFlexpayService = new FlexpayService(
  commonStorage,
  api
);
export const bankService: IBankService = new BankService(
  commonStorage,
  api
);
