export async function runSafely<T extends any | undefined>(
  func: () => any
): Promise<T> {
  try {
    return await func();
  } catch (e){
    return undefined;
  }
}

export const isConditionShowWidth = (value: number) => {
  const width = window.innerWidth;
  if (width >= value) return true;
  return false;
};
