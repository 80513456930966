import React from "react";
import "./style.css";
import classNames from "classnames";

export type TextColor = "black" | "blue" | "white" | "red" | "green";
interface Props {
  color?: TextColor | "default";
  size?: 10 | 12 | 14 | 18 | 40 | 32 | 24 | 20 | 16;
  onClick?: () => any;
  className?: string;
  style?: React.CSSProperties;
  float?: "left" | "right";
}

function defineIcon(className: string) {
  return (props: Props) => {
    return (
      <div
        style={{
          width: props.size,
          height: props.size,
          cursor: props.onClick ? "pointer" : "default",
          ...props.style,
        }}
        className={classNames(
          "post-icon",
          className,
          props.className || "",
          "post-icon-float-" + (props.float || "default"),
          props.color
        )}
        onClick={props.onClick}
      ></div>
    );
  };
}

export default {
  Logo: defineIcon("main-logo"),
  Home: defineIcon("home"),
  ChevronRight: defineIcon("chevron-right"),
  ChevronLeft: defineIcon("chevron-left"),
  ChevronUp: defineIcon("chevron-up"),
  ChevronDown: defineIcon("chevron-down"),
  EmptyList: defineIcon("empty"),
  Transaction: defineIcon("transaction"),
  GridMenu: defineIcon("grid-menu"),
  Management: defineIcon("management"),
  Info: defineIcon("info"),
  Information: defineIcon("information"),
  Settings: defineIcon("settings"),
  Report: defineIcon("report"),
  ArrowLeft: defineIcon("arrows-left"),
  Loader: defineIcon("spinner"),
  Calendar: defineIcon("calendar"),
  Caretdown: defineIcon("caretdown"),
  Help: defineIcon("help"),
  Logout: defineIcon("logout"),
  Search: defineIcon("search"),
  ProductPlaceholder: defineIcon("product-placeholder"),
  Add: defineIcon("add"),
  Session: defineIcon("session"),
  Reload: defineIcon("reload"),
  Question: defineIcon("question"),
  Shop: defineIcon("shop"),
  Lock: defineIcon("lock"),
  UserSession: defineIcon("user-session"),
  timeClock: defineIcon("time-clock"),
  Download: defineIcon("download"),
  logoBCA: defineIcon("bank-logo-bca"),
  ShowPass: defineIcon("password-show"),
  ShowHide: defineIcon("password-hide"),
  Cross: defineIcon("cross"),
  Close: defineIcon("close"),
  Close24: defineIcon("close-24"),
  Checklist: defineIcon("checklist"),
  SortCaretup: defineIcon("sort-caretup"),
  SortCaretdown: defineIcon("sort-caretdown"),
  Bank: defineIcon("bank"),
  Minimarket: defineIcon("market"),
  BCA: defineIcon("bank-bca"),
  BRI: defineIcon("bank-bri"),
  BNI: defineIcon("bank-bni"),
  DANAMON: defineIcon("bank-danamon"),
  MANDIRI: defineIcon("bank-mandiri"),
  KEBHANA: defineIcon("bank-kebhana"),
  ALTO: defineIcon("bank-alto"),
  PRIMA: defineIcon("bank-prima"),
  PERMATA: defineIcon("bank-permata"),
  CIMB: defineIcon("bank-cimb"),
  ATMBERSAMA: defineIcon("bank-atmbersama"),
  ALFAMIDI: defineIcon("market-alfamidi"),
  ALFAMART: defineIcon("market-alfamart"),
  LAWSON: defineIcon("market-lawson"),
  DANDAN: defineIcon("market-dandan"),
  OnboardDownload: defineIcon("step-download"),
  OnboardManagement: defineIcon("step-management"),
  OnboardPost: defineIcon("step-post"),
  playVideo: defineIcon("play-video"),
  EyeHidden: defineIcon("eye-hidden"),
  EyeShow: defineIcon("eye-show"),
  SuccessSign: defineIcon("success-sign"),
  BadgePremium: defineIcon("badge-premium"),
  BadgeLite: defineIcon("badge-lite"),
  HelpInfo: defineIcon("help-info"),
  HelperImage: defineIcon("help-image"),
  Phone: defineIcon("phone"),
  Mail: defineIcon("mail"),
  ActivityListing: defineIcon("activity-list"),
  Papper: defineIcon("papper"),
  Gopay: defineIcon("gopay"),
  MEGA: defineIcon("mega"),
  Cashlez: defineIcon("cashlez"),
  LinkAja: defineIcon("linkaja"),
  Dana: defineIcon("dana"),
  Ovo: defineIcon("ovo"),
  EmptyEmployee: defineIcon("empty-employee"),
  EmptyDevice: defineIcon("empty-device"),
  File: defineIcon("file"),
};
