import { PaymentMethodIntegrationStatus, PaymentMethodKYCForm, PaymentMethodKYCFormQueryString, PaymentMethodKYCFormDetail, CashlezPaymentDetailUpdateBody, ShopeePaymentDetailUpdateBody, BankOption, BankOptionBody } from "../models";
import { BaseService } from "domains/commons/services";
import { ICommonStorage } from "domains/commons/contracts/storage";
import { Api } from "domains/contracts/api";
import { IPaymentMethodKYCStorage } from "../contracts/storage";
import { toDict } from "utilities/converter/list";

export interface IPaymentMethodKYCService {
  getPaymentMethodIntegrationStatuses: () => Promise<PaymentMethodIntegrationStatus[]>;
  getPaymentMethodKYCFormList: (queryParams?: PaymentMethodKYCFormQueryString) => Promise<PaymentMethodKYCForm[]>;
  getCashlezKYCFormDetail: (paymentMethodKYCFormId: string) => Promise<PaymentMethodKYCFormDetail>;
  getShopeeKYCFormDetail: (paymentMethodKYCFormId: string) => Promise<PaymentMethodKYCFormDetail>;
  updateCashlezPaymentDetail: (paymentMethodKYCFormId: string, cashlezPaymentDetail: CashlezPaymentDetailUpdateBody) => Promise<void>;
  updateShopeePaymentDetail: (paymentMethodKYCFormId: string, shopeePaymentDetail: ShopeePaymentDetailUpdateBody) => Promise<void>;
  loadMore: () => Promise<PaymentMethodKYCForm[]>;
  getBankOptions: () => Promise<BankOption[]>;
  updateBankAccount: (paymentMethodKYCFormId: string, integratorId: string, bankOptionBody: BankOptionBody) => Promise<void>;
}

export class PaymentMethodKYCService extends BaseService implements IPaymentMethodKYCService {
  paymentMethodKYCStorage: IPaymentMethodKYCStorage;

  constructor(commonStorage: ICommonStorage, api: Api, paymentMethodKYCStorage: IPaymentMethodKYCStorage) {
    super(commonStorage, api);
    this.paymentMethodKYCStorage = paymentMethodKYCStorage;
  }

  private getLastPaymentMethodKYCFormId (paymentMethodKYCFormList: PaymentMethodKYCForm[]): string | undefined {
    return paymentMethodKYCFormList.length > 0 ? paymentMethodKYCFormList[paymentMethodKYCFormList.length-1].formId : undefined
  }

  public async getPaymentMethodIntegrationStatuses() {
    try {
      const response = await this.api.paymentMethodKYC.getPaymentMethodIntegrationStatuses();
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async getPaymentMethodKYCFormList(queryParams?: PaymentMethodKYCFormQueryString) {
    try {
      const loadingKey = 'GET_PAYMENT_METHOD_KYC_LIST';
      this.commonStorage.setLoading(loadingKey, true);
      const response = await this.api.paymentMethodKYC.getPaymentMethodKYCFormList(queryParams);
      this.paymentMethodKYCStorage.setPaymentMethodKYCFormDict(toDict(response, (x) => x.formId));
      const lastPaymentMethodKYCFormId = this.getLastPaymentMethodKYCFormId(response);
      this.paymentMethodKYCStorage.setGettingListRequest({
        ...queryParams,
        startingAfter: lastPaymentMethodKYCFormId || queryParams.startingAfter  
      } as PaymentMethodKYCFormQueryString);
      this.paymentMethodKYCStorage.setListReachBottom(response.length === 0);
      this.commonStorage.setLoading(loadingKey, false);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async getCashlezKYCFormDetail(paymentMethodKYCFormId: string) {
    try {
      const response = await this.api.paymentMethodKYC.getPaymentMethodKYCFormDetail(paymentMethodKYCFormId, 'cashlez');
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async getShopeeKYCFormDetail(paymentMethodKYCFormId: string) {
    try {
      const response = await this.api.paymentMethodKYC.getPaymentMethodKYCFormDetail(paymentMethodKYCFormId, 'shopee');
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async updateCashlezPaymentDetail(paymentMethodKYCFormId: string, cashlezPaymentDetail: CashlezPaymentDetailUpdateBody) {
    try {
      const response = await this.api.paymentMethodKYC.updateCashlezPaymentDetail(paymentMethodKYCFormId, cashlezPaymentDetail);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async updateShopeePaymentDetail(paymentMethodKYCFormId: string, shopeePaymentDetail: ShopeePaymentDetailUpdateBody) {
    try {
      const response = await this.api.paymentMethodKYC.updateShopeePaymentDetail(paymentMethodKYCFormId, shopeePaymentDetail);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async loadMore () {
    const { paymentMethodKYCListManager } = this.getState().paymentMethodKYC;
    const queryParams = paymentMethodKYCListManager.request;
    if (!queryParams.startingAfter) {
      return Promise.resolve([]);
    }
    if(paymentMethodKYCListManager.hasReachedBottom){
      return Promise.resolve([]);
    }
    if(paymentMethodKYCListManager.isLoadingMore){
      return Promise.resolve([]);
    }
    this.paymentMethodKYCStorage.setLoadingMore(true);
    const paymentMethodKYCList = await this.api.paymentMethodKYC.getPaymentMethodKYCFormList(queryParams);
    this.paymentMethodKYCStorage.pushPaymentMethodKYCList(toDict(paymentMethodKYCList, (kycForm: PaymentMethodKYCForm) => kycForm.formId));
    const lastPaymentMethodKYCFormId = this.getLastPaymentMethodKYCFormId(paymentMethodKYCList);
    this.paymentMethodKYCStorage.setLoadingMore(false);
    this.paymentMethodKYCStorage.setGettingListRequest({
      ...queryParams,
      startingAfter: lastPaymentMethodKYCFormId || queryParams.startingAfter
    } as PaymentMethodKYCFormQueryString);
    this.paymentMethodKYCStorage.setListReachBottom(paymentMethodKYCList.length < queryParams.limit);
    return paymentMethodKYCList;
  };

  public async getBankOptions () {
    try {
      const response = await this.api.paymentMethodKYC.getBankOptions();
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async updateBankAccount (paymentMethodKYCFormId: string, integratorId: string, bankOptionBody: BankOptionBody) {
    try {
      const response = await this.api.paymentMethodKYC.updateBankAccount(paymentMethodKYCFormId, integratorId, bankOptionBody);
      return response;
    } catch (error) {
      throw error;
    }
  }
}