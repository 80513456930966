import { Dict } from 'utilities/type';
import { ColumnConfig, TableConfig } from './converter';


export interface KeyValueSegmentInformation<T, S> {
    orderedKeys: string[],
    itemWithSegments: ItemWithSegment<T, S>[]
    availableSegments: Dict<S[]>
}
export interface ItemWithSegment<T, S = any> {
    item: T,
    segments: Dict<S>
}


export function generateKeyValueInformation<T, S>(
    list: T[],
    toKeyValues: (item: T) => Dict<S>,
    sortingKeysFunc: (a: string, b: string, availableSegments: Dict<S[]>) => number = (a, b) => 0
): KeyValueSegmentInformation<T, S> {

    const itemWithSegments: ItemWithSegment<T, S>[] = list.map((item: T) => ({
        segments: toKeyValues(item),
        item
    }))
    const availableSegments: Dict<S[]> = {}
    itemWithSegments.forEach((x: ItemWithSegment<T, S>) => {
        Object.keys(x.segments).forEach((key: string) => {
            if (!availableSegments[key]) {
                availableSegments[key] = []
            }
            availableSegments[key].push(x.segments[key])
        })
    })
    const orderedKeys = Object.keys(availableSegments).sort((a, b) => sortingKeysFunc(a,b, availableSegments))
    return {
        orderedKeys,
        itemWithSegments: itemWithSegments,
        availableSegments
    }

}


export interface SegmentedData<X, Row, A, B> {
    tableConfig: TableConfig<X, Row>
    list: X[],
    keyValueSegmentInformation: KeyValueSegmentInformation<A, B>
}

export function toListAsRowsWithKeysAsSegmentedColumns<T, Row, Col, S = any>(
    stickyColumns: ColumnConfig<ItemWithSegment<T, S>, Col>[],
    list: T[],
    toKeyValues: (item: T) => Dict<S>,
    columnConfigFunc: (key: string) => ColumnConfig<ItemWithSegment<T, S>, Col>[],
    sortingKeysFunc: (a: string, b: string, availableSegments: Dict<S[]>) => number = (a, b) => 0,
    convertToTableConfigFunc: (configs: ColumnConfig<ItemWithSegment<T, S>, Col>[]) => TableConfig<ItemWithSegment<T, S>, Row>

): SegmentedData<ItemWithSegment<T, S>, Row, T, S> {
    const keyValueSegmentInformation: KeyValueSegmentInformation<T, S> = generateKeyValueInformation(list, toKeyValues, sortingKeysFunc)
    let segmentedColumns: ColumnConfig<ItemWithSegment<T, S>, Col>[] = []
    keyValueSegmentInformation.orderedKeys.forEach(key => {
        segmentedColumns = segmentedColumns.concat(columnConfigFunc(key))
    })


    const tableConfig: TableConfig<ItemWithSegment<T, S>, Row> = convertToTableConfigFunc([
        ...stickyColumns,
        ...segmentedColumns
    ])

    return {
        tableConfig,
        list: keyValueSegmentInformation.itemWithSegments,
        keyValueSegmentInformation
    }
}


export function toListAsSegmentedColumnsWithKeysAsRows<T, Row, Col, S = any>(
    stickyColumns: ColumnConfig<string, Col>[],
    list: T[],
    toKeyValues: (item: T) => Dict<S>,
    columnConfigFunc: (itemWithSegment: ItemWithSegment<T, S>) => ColumnConfig<string, Col>[],
    sortingKeysFunc: (a: string, b: string, availableSegments: Dict<S[]>) => number = (a, b) => 0,
    convertToTableConfigFunc: (configs: ColumnConfig<string, Col>[]) => TableConfig<string, Row>

    ): SegmentedData<string, Row, T, S> {

    const keyValueSegmentInformation = generateKeyValueInformation(list, toKeyValues, sortingKeysFunc)

    let segmentedColumns: ColumnConfig<string, Col>[] = []


    keyValueSegmentInformation.itemWithSegments.forEach(
        (itemWithSegment: ItemWithSegment<T, S>) => {
            segmentedColumns = segmentedColumns.concat(columnConfigFunc(itemWithSegment))
        })


    const tableConfig: TableConfig<string, Row> = convertToTableConfigFunc([
        ...stickyColumns,
        ...segmentedColumns
    ])

    return {
        tableConfig,
        list: keyValueSegmentInformation.orderedKeys,
        keyValueSegmentInformation
    }
}


