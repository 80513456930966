import { EnvConfig } from "config/def"
import defaultConfig from "config/default";


const config: EnvConfig = {
    googleClientId: '837117142790-b1m3r840rq5c79bfbi1dmsfa5q3i7pi6.apps.googleusercontent.com',
    featureFlags: {
        ...defaultConfig.featureFlags
    }
}
export default config