import React from 'react';
import { Route } from "react-router-dom";
import { RouteItem } from './route.item';
import AsyncPage from 'views/components/AsyncPage';

let parsedRoutes:  RouteItem[] = [];

function mapRoute(routes: RouteItem[], parent: RouteItem = null) {
  routes.forEach((route: RouteItem) => {
    if ( route.children || parent ) {
      if(parent) {
       route = { ...route}
       route.middleware = parent.middleware
        parsedRoutes.push(route)
      }
      else {
        mapRoute(route.children, route)
      }
    }
    else {
      parsedRoutes.push(route)
    }
  })
}

export function routeFactory(routeObj: RouteItem[]) {
  parsedRoutes = [];
  mapRoute(routeObj)
  return parsedRoutes.map((value: RouteItem, index: number ) => {
   return (
      <Route
      key={index}
      exact
      component={AsyncPage(() =>
        import('../../../src/' + value.component),
        value.middleware,
      )}
      path={value.path} 
      />
   )
  })
}