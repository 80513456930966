import { IDisbursementStorage } from "domains/disbursement/contract/storage";
import { DisbursementModel, ShopeeDisbursementSummaryModel, GettingDisbursementRequest } from "domains/disbursement/model";
import { BaseStorage } from "storages/base";
import { Dict } from "utilities/type";
import { PUSH_DISBURSEMENT, SET_DISBURSEMENT, SET_GETTING_LIST_REQUEST_DISBURSEMENT, SET_DISBURSEMENT_LIST_REACH_BOTTOM, SET_LOADING_MORE_DISBURSEMENT, SET_SHOPEE_DISBURSEMENT_SUMMARY } from "./actions";

export class DisbursementStorage extends BaseStorage implements IDisbursementStorage {
  public setDisbursements(disbursements: Dict<DisbursementModel>): void {
    this.dispatch(SET_DISBURSEMENT, disbursements);
  }

  public setShopeeDisbursementSummaries(shopeeDisbursementSummaryDict: Dict<ShopeeDisbursementSummaryModel>): void {
    this.dispatch(SET_SHOPEE_DISBURSEMENT_SUMMARY, shopeeDisbursementSummaryDict);
  }

  public pushDisbursements(disbursements: Dict<DisbursementModel>): void {
    this.dispatch(PUSH_DISBURSEMENT, disbursements);
  }

  public setReachBottom(hasReachedBottom: boolean): void {
    this.dispatch(SET_DISBURSEMENT_LIST_REACH_BOTTOM, hasReachedBottom)
  }

  public setLoadingMore(isLoadingMoreSubscription: boolean): void {
    this.dispatch(SET_LOADING_MORE_DISBURSEMENT, isLoadingMoreSubscription)
  }
  
  public setGettingListRequest(request: GettingDisbursementRequest): void {
    this.dispatch(SET_GETTING_LIST_REQUEST_DISBURSEMENT, request)
  }
}
