import { BaseStorage } from "storages/base";
import { Dict } from "utilities/type";
import { IShopeeOrderStorage } from "domains/shopeeOrder/contracts/storage";
import { ShopeeOrderModel, GettingShopeeOrderRequest, ShopeeOrderSummary } from "domains/shopeeOrder/models";
import { SET_SHOPEE_ORDER, PUSH_SHOPEE_ORDER, SET_SHOPEE_ORDER_LIST_REACH_BOTTOM, SET_LOADING_MORE_SHOPEE_ORDER, SET_GETTING_SHOPEE_ORDER_LIST_REQUEST, SET_SHOPEE_ORDER_SUMMARY } from "./actions";

export class ShopeeOrderStorage extends BaseStorage implements IShopeeOrderStorage {
  setShopeeOrders(shopeeOrders: Dict<ShopeeOrderModel>): void {
    this.dispatch(SET_SHOPEE_ORDER, shopeeOrders);
  }

  setShopeeOrderSummaries(shopeeOrderSummaries: Dict<ShopeeOrderSummary>): void {
    this.dispatch(SET_SHOPEE_ORDER_SUMMARY, shopeeOrderSummaries);
  }

  pushShopeeOrders(shopeeOrders: Dict<ShopeeOrderModel>) {
    this.dispatch(PUSH_SHOPEE_ORDER, shopeeOrders);
  };

  setListReachBottom(hasReachedBottom: boolean) {
    this.dispatch(SET_SHOPEE_ORDER_LIST_REACH_BOTTOM, hasReachedBottom);
  }

  setLoadingMore(isLoadingMore: boolean) {
    this.dispatch(SET_LOADING_MORE_SHOPEE_ORDER, isLoadingMore);
  }

  setGettingListRequest(request: GettingShopeeOrderRequest) {
    this.dispatch(SET_GETTING_SHOPEE_ORDER_LIST_REQUEST, request);
  }
}
