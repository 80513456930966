import React from 'react'
// import { history } from 'storages/store';
import './style.css';
import { Text, Icon } from '..';
import { RouteDescription } from 'utilities/router/route.item';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
//import { layoutService } from 'injectors';
import selector from './selector'

interface ChildSideBarProps {
  isDisplayed: boolean,
}

const SidebarChild: React.FC<ChildSideBarProps> = (props) => {
  const { isDisplayed } = props;
  const {selectedRoute} = useSelector(selector)

  return (
    <div className={`sidebar ${isDisplayed ? 'visible' : ''}`} style={{ left: isDisplayed ? -240 : -240 }}>
      <div className="post-sidebar">
        <div className="post-sidebar-child">
          <div className="post-sidebar-child-headtext">
            <Icon.ArrowLeft />
            <Text.Heading h={5} align="left">{'text'}</Text.Heading>
          </div>
          <div className="post-sidebar-child-menu">
            <div>
              {
                selectedRoute.childrenDescription &&  selectedRoute.childrenDescription.map((route: RouteDescription) =>
                  !route.hideSidebar && (
                    <div className={classNames({'ant-menu-item-deselected': route.path !== ''})} key={route.key}>
                    <Text.Span size={14} align="left">{route.text}</Text.Span>
                  </div>
                  )
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SidebarChild;