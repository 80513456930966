import React from 'react'

export interface SubItemFormContext<T extends object=any> {
    prefixName: string
    mapSubItem: (val: T) => any
}

export default React.createContext<SubItemFormContext<any>>({
    mapSubItem: (val: any) => val,
    prefixName: ""
});