import { BaseApi } from "api/base.api";
import { IPaymentMethodKYCApi } from "domains/paymentMethodKYC/contracts/api";
import { PaymentMethodIntegrationStatus, PaymentMethodKYCForm, PaymentMethodKYCFormQueryString, PaymentMethodKYCFormDetail, CashlezPaymentDetailUpdateBody, ShopeePaymentDetailUpdateBody, BankOption, BankOptionBody } from "domains/paymentMethodKYC/models";

export default class PaymentMethodKYCApi extends BaseApi implements IPaymentMethodKYCApi {
  constructor() {
    super('/admin/v2')
  }

  public async getPaymentMethodIntegrationStatuses(): Promise<PaymentMethodIntegrationStatus[]> {
    return this.makeForCamelCaseForList('GET', '/payment-methods/integration-statuses');
  }

  public async getPaymentMethodKYCFormList(queryParams: PaymentMethodKYCFormQueryString): Promise<PaymentMethodKYCForm[]> {
    return this.makeForCamelCaseForList('GET', '/kyc-forms', queryParams);
  }

  public async getPaymentMethodKYCFormDetail(paymentMethodKYCFormId: string, integratorId: string): Promise<PaymentMethodKYCFormDetail> {
    return this.makeForCamelCase('GET', `/kyc-forms/${paymentMethodKYCFormId}/${integratorId}`);
  }

  public async updateCashlezPaymentDetail(paymentMethodKYCFormId: string, cashlezPaymentDetail: CashlezPaymentDetailUpdateBody): Promise<void> {
    return this.makeForCamelCase('POST', `/kyc-forms/${paymentMethodKYCFormId}/cashlez-activation`, cashlezPaymentDetail);
  } 

  public async updateShopeePaymentDetail(paymentMethodKYCFormId: string, shopeePaymentDetail: ShopeePaymentDetailUpdateBody): Promise<void> {
    return this.makeForCamelCase('POST', `/kyc-forms/${paymentMethodKYCFormId}/shopee-activation`, shopeePaymentDetail);
  } 
  
  public async getBankOptions(): Promise<BankOption[]> {
    return this.makeForCamelCaseForList('GET', '/kyc-data/banks')
  }

  public async updateBankAccount(paymentMethodKYCFormId: string, integratorId: string, bankOptionBody: BankOptionBody): Promise<void> {
    return this.makeForCamelCase('POST', `/kyc-forms/${paymentMethodKYCFormId}/${integratorId}/bank-accounts`, bankOptionBody);
  }
}
