import React from "react";
import classNames from "classnames";
import "./style.css";

interface Props {
  position?: "horizontal" | "vertical";
  margin?: 8 | 16 | 24 | 32 | 40;
}

const Divider = (props: Props) => {
  const className = classNames(
    "post-divider-" + (props.position || "horizontal"),
    "post-divider-" + (props.margin || 8)
  );
  return <div className={className} />;
};

export default Divider;
export { Divider };
