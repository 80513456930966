import "./style.css"
import { useSelector } from 'react-redux';
import React, { FunctionComponent } from 'react'
import classNames from 'classnames'
import { ICommonService } from "domains/commons/services";
import { commonServices } from "injectors";
import selector from './selector'
import { defer, Deferred } from "utilities/defer";
import { ModalConfig, getModalConfig } from "./config";
import { history } from "storages/store";


export const ModalContainer: React.FC<{}> = () => {
    const { controllers } = useSelector(selector)
    return (
        <div className="modal-container">
            {controllers.map(c => c.component)}
        </div>
    )
}


export interface ModalProps<TOut = any> {
    close: (returnedValue: TOut) => any
    dismiss: () => any
}



export class ModalController<Props = any, TOut = any> implements ModalProps {
    deferred: Deferred<TOut>
    isClosed: boolean = false
    close(item: TOut) {
        this.deferred.resolve(item)
        this.isClosed = true
        this.goBack()
    }
    dismiss() {
        this.dismissWithoutRouting();
        this.goBack()
    }

    goBack(){
        if(this.config && this.config.path && history.location.pathname == this.config.path){
            history.goBack()
        }
    }

    dismissWithoutRouting(){
        this.deferred.reject()
        this.isClosed = true
        
    }


    component: React.ReactElement<Props & ModalProps<TOut>>
    id: string
    config?: ModalConfig

    constructor(id: string, fc: FunctionComponent<Props & ModalProps<TOut>>, props: Props, config?: ModalConfig) {
        this.id = id
        this.deferred = defer()
        this.config = config
        this.component = React.createElement(fc, {
            ...props,
            close: (x: TOut) => this.close(x),
            dismiss: () => this.dismiss(),
        })
        if (config && config.path) {
            history.push(config.path)
            window.addEventListener('popstate', (ev) => {
                if(!this.isClosed){
                    this.dismissWithoutRouting()
                }
            });
        }


    }
}

export class ModalService {

    commonServices: ICommonService
    constructor(commonServices: ICommonService) {
        this.commonServices = commonServices
    }

    async open<Props, TOut>(fc: FunctionComponent<Props & ModalProps<TOut>>, props: Props): Promise<TOut> {
        const id = new Date().getTime().toString()
        const config = getModalConfig(fc)
        const modalController = new ModalController<Props, TOut>(id, fc, props, config)
        modalController.deferred.promise.finally(() => {
            this.remove(modalController.id)
        })
        this.commonServices.pushModal(modalController)
        return modalController.deferred.promise
    }

    remove(id: string) {
        this.commonServices.removeModal(id)
    }
}

export interface Props {
    dismiss: () => any
    children: any[] | any
    type: "full" | "blank"
    width?: string
    className?: string
    canDismissOutside?: boolean;
    style?: React.CSSProperties;
}

export const Modal: React.FC<Props> = (props: Props) => {
    const style: React.CSSProperties = {
        ...props.style
    }
    if (props.width) {
        style.width = props.width
    }

    const className = classNames(
        'modal-panel',
        props.className || '',
        props.type ? 'modal-panel-' + props.type : ''
    )

    let onClickOutside = () => {
        props.dismiss()
    }
    if (props.canDismissOutside == false) {
        onClickOutside = () => { }
    }

    return (
        <div className="modal-background" onClick={onClickOutside} style={{ zIndex: 9999 }}>
            <div className={className} style={style}>
                <React.Fragment>
                    {props.children}
                </React.Fragment>
            </div>
        </div>
    )
}


export const DefaultModalService = new ModalService(commonServices)