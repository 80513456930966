import { BaseStorage } from "storages/base";
import { ISubscriptionStorage } from '../../domains/subscription/contracts/storage';
import { SET_SUBSCRIPTION, PUSH_SUBSCRIPTION, SET_SUBSCRIPTION_LIST_REACH_BOTTOM, SET_LOADING_MORE_SUBSCRIPTION, SET_GETTING_SUBSCRIPTION_LIST_REQUEST } from "./actions";
import { SubscriptionModel, GettingSubscriptionsRequest } from "domains/subscription/models";
import { Dict } from "utilities/type";


export class SubscriptionStorage extends BaseStorage implements ISubscriptionStorage {
  setSubscriptions(subscriptions: Dict<SubscriptionModel>): void {
    this.dispatch(SET_SUBSCRIPTION, subscriptions);
  }

  public pushSubscriptions(subscriptionDict: Dict<SubscriptionModel>): void {
    this.dispatch(PUSH_SUBSCRIPTION, subscriptionDict)
  }

  public setSubscriptionsReachBottom(hasReachedBottom: boolean): void {
    this.dispatch(SET_SUBSCRIPTION_LIST_REACH_BOTTOM, hasReachedBottom)
  }

  public setLoadingMoreSubscriptions(isLoadingMoreSubscription: boolean): void {
    this.dispatch(SET_LOADING_MORE_SUBSCRIPTION, isLoadingMoreSubscription)
  }
  
  public setGettingSubscriptionListRequest(request: GettingSubscriptionsRequest): void {
    this.dispatch(SET_GETTING_SUBSCRIPTION_LIST_REQUEST, request)
  }
}
