import React, { useEffect, useState } from "react";
import "./style.css";
import "./responsiveStyle.css";
import { Sidebar, SwitchRoute, Wrapper } from "views/components";
import { APP_ROUTES } from "routes/app-routes";
import { useSelector } from "react-redux";
import { AppState } from "domains/contracts/appState";

const AuthorizedLayoutContent: React.FC = (props) => {
  return <div className="layout-content">{props.children}</div>;
};

function openNav() {
  document.getElementById("mySidenav").style.width = "240px";
}

const AuthorizedLayout: React.FC = () => {
  const userState = useSelector((state: AppState) => state.user);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isUserFinance, setIsUserFinance] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  useEffect(() => {
    if (userState.id) {
      const userActiveRoles = userState.roles
        ? userState.roles.filter((r) => r.active)
        : [];
      setIsUserAdmin(userActiveRoles.map((r) => r.name).includes("post.admin"));
      setIsUserFinance(
        userActiveRoles.map((r) => r.name).includes("post.finance")
      );
      setIsSidebarVisible(true);
    }
  }, [userState]);

  return (
    <React.Fragment>
      <div id="mySidenav" className="sidenav desktop-none">
        <pre></pre>
      </div>
      <div id="SidebarBlock" className="mobile-none">
        {isSidebarVisible && (
          <Sidebar isUserAdmin={isUserAdmin} isUserFinance={isUserFinance} />
        )}
      </div>
      <AuthorizedLayoutContent>
        <Wrapper padding="40px" className="layout-content-body">
          <span
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={() => openNav()}
            className="desktop-none"
          >
            &#9776;
          </span>
          <SwitchRoute routes={APP_ROUTES} />
        </Wrapper>
      </AuthorizedLayoutContent>
    </React.Fragment>
  );
};

export default AuthorizedLayout;
