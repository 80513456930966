import { BaseApi } from "api/base.api";
import { ITokofiQRISDisbursementApi } from "domains/tokofiQRISDisbursement/contract/api";
import {
  DisbursementModel,
  DisbursementQueryParams,
  DisbursementStatusBodyRequest,
} from "domains/tokofiQRISDisbursement/models";

export default class TokofiQRISDisbursementApi
  extends BaseApi
  implements ITokofiQRISDisbursementApi
{
  constructor() {
    super("admin/v2/reports");
  }

  public async getDisbursementList(
    queryParams?: DisbursementQueryParams
  ): Promise<DisbursementModel[]> {
    return this.makeForCamelCaseForList(
      "GET",
      "/disbursement-list",
      queryParams
    );
  }

  public async updateDisbursementStatus(
    disbursement: DisbursementStatusBodyRequest
  ): Promise<any> {
    return this.makeForCamelCaseForList(
      "PUT",
      "/disbursement-list/disbursement-status",
      disbursement
    );
  }
}
