import React from 'react'
import classNames from 'classnames';
import './style.css';
import { BaseClickableProps, BaseParentProps } from '../props';
import { AlignmentClass, SizeClass } from '../const';

interface Props extends BaseClickableProps, BaseParentProps{
  size?: SizeClass;
  padding?: 0 | 8 | 16 | 24 | 32 | 40 | 64;
  align?: AlignmentClass;
  type?: 'bordered' | 'shadow';
  width?: string;
  bgColor?: 'white' | 'transparent';
  position?: 'middle';
}

const Card = (props: Props) => {
  const className = classNames('post-card',
    props.className || '',
    props.size,
    props.align,
    props.type ? 'post-card-type-' + props.type : '',
    props.padding ? 'post-padding-' + props.padding : '',
    props.bgColor ? 'post-card-bgcolor-' + props.bgColor : '',
    props.position ? 'post-wrapper-position-' + props.position : '')
  const style: React.CSSProperties = { ...props.style, width: props.width }
  return (
    <div style={style} className={className} onClick={props.onClick}>
      {props.children}
    </div>
  )
}

export default Card;
