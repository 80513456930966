import moment from "moment";
import { DateRange } from "domains/commons/models";

export function isDateInBetween(day: Date, range?: DateRange): boolean {
    if (!range) {
        return false;
    }
    return moment(day).isBetween(
        range.from,
        range.to
    )
}

export function isAtStartOrEnd(day: Date, range?: DateRange): boolean {
    if (!range) {
        return false;
    }
    return moment(day).isSame(range.from) || moment(day).isSame(range.to)
}
export function isAtStart(day: Date, range?: DateRange): boolean {
    if (!range) {
        return false;
    }
    return moment(day).isSame(range.from)
}

export function isAtEnd(day: Date, range?: DateRange): boolean {
    if (!range) {
        return false;
    }
    return moment(day).isSame(range.to)
}



export interface TimerCountdown {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    difference: number;
}


export function calculateTimeLeft(endTime: Date): TimerCountdown | null {

    const difference: number = +moment(endTime) - +moment();

    if (difference > 0) {
        return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
            difference
        };
    }
    return null;
};


export class CountdownCounter {
    endTime: Date
    intervalRef: any

    clearInterval(){
        if(this.intervalRef){
            clearInterval(this.intervalRef)
        }
    }

    callback: (timerCountdown: TimerCountdown | null) => any
    start(endTime: Date, updateFrequency: number = 1000) {
        this.endTime = endTime
        const func = () => {
            const timerCountdown = calculateTimeLeft(endTime)
            if(!timerCountdown){
                this.clearInterval()
            }
            this.callback(timerCountdown)
        }
        this.clearInterval()
        this.intervalRef = setInterval(func, updateFrequency)
        func()
    }

    constructor(callback: (timerCountdown: TimerCountdown | null) => any) {
        this.callback = callback
    }
}