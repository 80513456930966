import { Reducer } from "utilities/redux/reducer";
import { Dict } from "utilities/type";
import { SET_VOUCHER, PUSH_VOUCHER, SET_LOADING_MORE_VOUCHER, SET_VOUCHER_LIST_REACH_BOTTOM, SET_GETTING_VOUCHER_LIST_REQUEST, SET_VOUCHER_RULES } from "./actions";
import { VoucherState } from "domains/voucher/states";
import { VoucherModel, GettingVouchersRequest, VoucherRuleModel} from "domains/voucher/models";

export default class VoucherReducer extends Reducer<VoucherState> {
  constructor() {
    super({
      vouchers: {},
      voucherRules: {},
      voucherListManager: {
        isLoadingMoreVoucher: false,
        request: {},
        hasReachedBottom: false
      }
    })
  }

  public setVouchers(state: VoucherState, vouchers: Dict<VoucherModel>): VoucherState {
    return {
      ...state,
      vouchers
    }
  }

  public setVoucherRules(state: VoucherState, voucherRules: Dict<VoucherRuleModel>): VoucherState {
    return {
      ...state,
      voucherRules
    }
  }

  public pushVouchers(state: VoucherState, vouchers: Dict<VoucherModel>): VoucherState {
    return {
      ...state,
      vouchers: {
        ...state.vouchers,
        ...vouchers
      }
    };
  };

  public setVoucherListReachBottom(state: VoucherState, hasReachedBottom: boolean): VoucherState {
    return {
      ...state,
      voucherListManager: {
        ...state.voucherListManager,
        hasReachedBottom
      }
    }
  }

  public setLoadingMoreVouchers(state: VoucherState, isLoadingMoreVoucher: boolean): VoucherState {
    return {
      ...state,
      voucherListManager: {
        ...state.voucherListManager,
        isLoadingMoreVoucher
      }
    }
  }


  public setGettingVoucherListRequest(state: VoucherState, request: GettingVouchersRequest): VoucherState {
    return {
      ...state,
      voucherListManager: {
        ...state.voucherListManager,
        request
      }
    }
  }

  get actions(): {
    [p: string]: (state: VoucherState, payload?: any) => any;
  } {
    return {
      [SET_VOUCHER]: this.setVouchers,
      [SET_VOUCHER_RULES]: this.setVoucherRules,
      [PUSH_VOUCHER]: this.pushVouchers,
      [SET_VOUCHER_LIST_REACH_BOTTOM]: this.setVoucherListReachBottom,
      [SET_LOADING_MORE_VOUCHER]: this.setLoadingMoreVouchers,
      [SET_GETTING_VOUCHER_LIST_REQUEST]: this.setGettingVoucherListRequest,
    };
  }
}
