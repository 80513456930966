import { AxiosError } from 'axios';
import { commonServices } from 'injectors';
import { clearLocalStorage } from 'utilities/localStorage';
import { history } from 'storages/store';
import ROUTE from 'constants/routes';

export const errorInterceptor = (error: AxiosError): Promise<Error> => {
  const { response } = error;
  if (!error.response && error.message === 'Network Error') {
    commonServices.setAlert({
      message: 'Koneksi internet tidak stabil',
      type: 'error'
    })
  }
  if ((response && response.status === 401) || (response && response.data.code === 'admin_login_failed')) {
    clearLocalStorage();
    history.push(ROUTE.LOGIN);
  }
  return Promise.reject(response ? response.data : error)
}