import { BaseApi } from "api/base.api";
import { LoginRequest } from "domains/auth/models";
import { IAuthApi } from "domains/auth/contracts/api";
import { UserModel } from "domains/user/models";

export default class AuthApi extends BaseApi implements IAuthApi {
  constructor() {
    super('/admin/v2');
  }

  public async login(query: LoginRequest): Promise<UserModel> {
    return this.makeForCamelCase('POST', '/login', query);
  }

  public async logout(): Promise<any> {
    return this.make('POST', '/logout');
  }
}