import prod from 'env/prod'
import staging from 'env/staging'
import local from 'env/local'
import { EnvConfig } from './def'
import { Dict } from 'utilities/type'

let config: Dict<Readonly<EnvConfig>> = {
    prod,
    local,
    staging,
}

export function getCurrentEnvironment(): Readonly<EnvConfig> {
    const env = process.env.REACT_APP_MODE || 'staging'
    console.log("Using environment from ", env)
    return config[env]
}