import appRoute from "./app";
import authRoute from "./auth";

const ROUTE = {
  MAINPAGE: '/',
  ...appRoute,
  ...authRoute
}

export default ROUTE;
