import React from "react";
import InputBase from "../base";
import Form, { RenderingFieldContext } from "../../Form";
import { InputNumber } from "antd";

interface Props {
  label?: string;
  placeholder?: string;
  name?: string;
  isDisabled?: boolean;
  style?: any;
  onChange?: (val: number | undefined) => any;
}

const InputPercentage = (props: Props) => {
  const handleOnChange = (val: number | undefined) => {
    if (props.onChange) {
      props.onChange(val);
    }
  };

  return (
    <Form.Field
      name={props.name}
      render={({ field, value, onChange, onBlur }: RenderingFieldContext) => (
        <InputBase
          label={props.label}
          isDisabled={props.isDisabled}
          error={field.isDirty && field.error}
        >
          <InputNumber
            name={props.name}
            value={props.isDisabled ? 100:value}
            placeholder={props.placeholder}
            min={0}
            max={100}
            disabled={props.isDisabled}
            formatter={(value) => `${value}%`}
            parser={(value) =>  value!.replace("%", "")}
            onChange={(evt: any) => {
              let val = parseFloat(evt);
              if (isNaN(val)) {
                val = undefined;
              }
              onChange(val);
              handleOnChange(val);
            }}
          />
        </InputBase>
      )}
    />
  );
};

export default InputPercentage;
