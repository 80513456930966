
import * as Xlsx from 'xlsx'
import * as FileSaver from 'file-saver';

function toUint8Array(characters: string): Uint8Array {
  const byteNumbers = new Array(characters.length);
  for (let i = 0; i < characters.length; i++) {
    byteNumbers[i] = characters.charCodeAt(i);
  }

  return new Uint8Array(byteNumbers);
}

interface FuncArgs<Ts extends any[], TOut = any> {
  (...args: Ts): TOut
}

export type CellType = string | number | boolean

export interface CSVColumnGenerator<T> {
  title: string;
  valueMapper: (item: T, index: number) => CellType
}

export function generate2DArrayFromList<T>(list: T[], columnGenerators: CSVColumnGenerator<T>[]): CellType[][] {
  let arr: CellType[][] = [];
  arr.push(columnGenerators.map(x => x.title.replace(/\,/gi, ' ')));
  list.forEach((item: T, index: number) => {
    arr.push(columnGenerators.map(x => x.valueMapper(item, index)));
  })
  return arr
}

export class XlsxGenerator {
  private wb: Xlsx.WorkBook
  constructor() {
    this.wb = Xlsx.utils.book_new();
  }

  getWorkbook(): Xlsx.WorkBook {
    return this.wb
  }

  withSheet(sheetName: string, arr: (string | number)[][], customSettingFunc?: FuncArgs<[Xlsx.WorkSheet]>) {
    const ws = Xlsx.utils.aoa_to_sheet(arr);
    if (customSettingFunc) {
      customSettingFunc(ws)
    }
    Xlsx.utils.book_append_sheet(this.wb, ws, sheetName);
    return this;
  }

  saveAs(fileName: string) {
    const fileType = 'application/vnd.ms-excel';
    const excelBuffer = Xlsx.write(this.wb, { bookType: 'xls', bookSST: true, type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + ".xls");
  }

  generateFileStringArray(options?: Xlsx.WritingOptions): string {
    return Xlsx.write(this.wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'binary',
      ...options
    })
  }

  generateBlob(): Blob {
    let fileStringArray = this.generateFileStringArray()
    return new Blob([toUint8Array(fileStringArray)], {
      type: "application/octet-stream"
    })
  }

}