import React from "react";
import InputBase from "../base";
import Form, { RenderingFieldContext } from "../../Form";
import NumberFormat from "react-number-format";

interface Props {
  label?: string;
  placeholder?: string;
  name?: string;
  isDisabled?: boolean;
  style?: any;
  onChange?: (val: number | undefined) => any;
}

const disabledInputStyle = {
  border: "solid 1px #757575",
  backgroundColor: "#e0e0e0",
  cursor: "pointer",
};


const InputNpwp = (props: Props) => {
  const handleOnChange = (val: number | undefined) => {
    if (props.onChange) {
      props.onChange(val);
    }
  };

  return (
    <Form.Field
      name={props.name}
      render={({ field, value, onChange, onBlur }: RenderingFieldContext) => (
        <InputBase
          label={props.label}
          isDisabled={props.isDisabled}
          error={field.isDirty && field.error}
        >
          <NumberFormat
            style={props.isDisabled ? disabledInputStyle : props.style}
            disabled={props.isDisabled}
            name={props.name}
            type="tel"
            placeholder="23.233.323.4-323.3234"
            format="##.###.###.#-###.####"
            onChange={(evt: any) => {
              onChange(evt.target.value);
              handleOnChange(evt.target.value);
            }}
            value={value}
          />
        </InputBase>
      )}
    />
  );
};

export default InputNpwp;
