import { BaseApi } from "api/base.api";
import { IDisbursementApi } from "domains/disbursement/contract/api";
import { DisbursementBodyRequest, DisbursementModel, ShopeeDisbursementSummaryModel, GetDisbursementQueryParam, GetShopeeDisbursementSummaryQueryParams } from "domains/disbursement/model";

export default class DisbursementApi extends BaseApi implements IDisbursementApi {
  constructor() {
    super('/admin/v2')
  }

  public async getDisbursementList(queryParams?: GetDisbursementQueryParam): Promise<DisbursementModel[]> {
    return this.makeForCamelCaseForList('GET', '/disbursements', queryParams);
  }

  public async getShopeeDisbursementSummaryList(queryParams?: GetShopeeDisbursementSummaryQueryParams): Promise<ShopeeDisbursementSummaryModel[]> {
    return this.makeForCamelCaseForList('GET', '/disbursement-summaries/shopee', queryParams)
  }

  public async batchCreateDisbursement(disbursement: DisbursementBodyRequest): Promise<DisbursementModel[]> {
    return this.makeForCamelCaseForList('POST', '/disbursements/batch-create', disbursement);
  }
}
