import React from "react";
import InputBase from "../baseRadio";
import { BaseProps } from "views/components/props";
import Form, { RenderingFieldContext } from "../../Form";

interface Props extends BaseProps {
  label?: string;
  placeholder?: string;
  name?: string;
  maxLength?: number;
  isDisabled?: boolean;
  value?:any;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  onChange?: (value: string) => any;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const disabledInputStyle = {
  border: "solid 1px #757575",
  backgroundColor: "#e0e0e0",
  cursor: "pointer",
};

const InputRadio = (props: Props) => {
  const handleOnChange = (value: string) => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <Form.Field
      name={props.name}
      render={({
        field,
        value,
        onChange,
        onBlur,
      }: RenderingFieldContext<any>) => (
        <InputBase
          isDisabled={props.isDisabled}
          label={props.label}
          error={field.isDirty && field.error}
          suffix={props.suffix}
          prefix={props.prefix}
        >
          <input
            style={props.isDisabled ? disabledInputStyle : null}
            name={props.name}
            type="radio"
            disabled={props.isDisabled}
            placeholder={props.placeholder}
            maxLength={props.maxLength}
            value={"PO Financing"}
            onBlur={onBlur}
            onChange={(evt) => {
              onChange(evt.target.value);
              handleOnChange(evt.target.value);
            }}
            onKeyDown={props.onKeyPress}
          />
        </InputBase>
      )}
    />
  );
};

export default InputRadio;
