import React, { useRef, useState } from "react";
import classNames from "classnames";
import "./style.css";
import { Icon, Text } from "..";

export interface ButtonDropdownItem {
  key: string;
  label: string;
  disabled?: boolean;
}

interface Props {
  theme: "primary" | "secondary" | "danger";
  label: string;
  fullW?: boolean;
  onClick?: (key: string) => any;
  disabled?: boolean;
  list: ButtonDropdownItem[];
}

const ButtonDropdown: React.FC<Props> = (props) => {
  const [popupVisibility, setPopupVisibility] = useState("hidden");
  const containerRef = useRef<HTMLDivElement>(null);

  const handleVisibility = () => {
    if (props.disabled) {
      return;
    }
    if (popupVisibility === "hidden") {
      setPopupVisibility("visible");
      document.addEventListener("click", handleOutsideClick, false);
    } else {
      setPopupVisibility("hidden");
      document.removeEventListener("click", handleOutsideClick, false);
    }
  };

  const handleOutsideClick = (e: any) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setPopupVisibility("hidden");
    }
  };

  const onItemClick = (key: string) => {
    if (!props.disabled && props.onClick) {
      props.onClick(key);
      handleVisibility();
    }
  };

  const classNameButton = classNames(
    "post-button-dropdown",
    props.theme,
    { "full-width": props.fullW },
    { disabled: props.disabled }
  );
  return (
    <div
      className="post-button-dropdown-wrapper"
      ref={containerRef}
      data-testid="button-dropdown-wrapper"
    >
      <button
        className={classNameButton}
        onClick={handleVisibility}
        data-testid="button-dropdown"
      >
        <Text.Paragraph size={14}>{props.label}</Text.Paragraph>
        <Icon.Caretdown color="white" />
      </button>

      <ul className={`post-button-dropdown-menu ${popupVisibility}`}>
        {props.list.map((item, i) => (
          <li
            className={classNames({ disabled: item.disabled })}
            key={i}
            onClick={() => !item.disabled && onItemClick(item.key)}
          >
            <Text.Span size={14} color="black">
              {item.label}
            </Text.Span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ButtonDropdown;
