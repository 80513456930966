import { BaseService } from "domains/commons/services";
import { history } from "storages/store";
import { CONFIG_LOCALSTORAGE } from "constants/localStorage";
import { setLocalStorage, clearLocalStorage } from "utilities/localStorage";
import ROUTE from "constants/routes";
import { LoginRequest } from "../models";
import { IUserStorage } from "domains/user/contracts/storage";
import { ICommonStorage } from "domains/commons/contracts/storage";
import { Api } from "domains/contracts/api";

export class AuthService extends BaseService implements IAuthService {
  userStorage: IUserStorage;

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    userStorage: IUserStorage
  ) {
    super(commonStorage, api);
    this.userStorage = userStorage;
  }

  public login = async (query: LoginRequest) => {
    try {
      const response = await this.api.auth.login(query);
      setLocalStorage(CONFIG_LOCALSTORAGE.GOOGLE_TOKEN_ID, query.googleTokenId);
      setLocalStorage(CONFIG_LOCALSTORAGE.EMAIL, query.email);
      setLocalStorage(CONFIG_LOCALSTORAGE.POST_TOKEN, response.id);
      this.userStorage.setUser(response);
      history.push(ROUTE.SUBSCRIPTION);
    } catch (error) {
      console.error(error);
    }
  };

  public reLogin = async (query: LoginRequest) => {
    try {
      const response = await this.api.auth.login(query);
      setLocalStorage(CONFIG_LOCALSTORAGE.GOOGLE_TOKEN_ID, query.googleTokenId);
      setLocalStorage(CONFIG_LOCALSTORAGE.EMAIL, query.email);
      setLocalStorage(CONFIG_LOCALSTORAGE.POST_TOKEN, response.id);
      this.userStorage.setUser(response);
    } catch (error) {
      console.error(error);
    }
  };

  public logout = async () => {
    try {
      await this.api.auth.logout();
      clearLocalStorage();
      history.push(ROUTE.LOGIN);
    } catch (error) {
      console.error(error);
      return;
    }
  };
}

export interface IAuthService {
  login(query: LoginRequest): Promise<void>;
  reLogin(query: LoginRequest): Promise<void>;
  logout(): Promise<void>;
}
