import { getCurrentEnvironment } from "./env";
import { EnvConfig } from "./def";

export function getAppConfig(): AppConfig {
  const detail = getCurrentEnvironment()
  return {
    apiUrl: process.env.REACT_APP_API_URL,
    detail,
    publicPath: process.env.REACT_APP_PUBLIC_PATH,
    mode: process.env.REACT_APP_MODE,
    flexpayApiUrl: process.env.REACT_APP_FLEXPAY_API_URL
  }
}

export interface AppConfig {
  apiUrl: string
  detail: EnvConfig
  publicPath: string
  mode: string
  flexpayApiUrl: string
}

export * from "./func";
