import { BaseService } from "domains/commons/services";
import { ICommonStorage } from "domains/commons/contracts/storage";
import { Api } from "domains/contracts/api";
import { ISubscriptionStorage } from "../contracts/storage";
import { toDict } from "utilities/converter/list";
import { SubscriptionPackagePricing, SubscriptionSaleType, OverrideUserSubscriptionBody, SubscriptionQueryString, SubscriptionModel, GettingSubscriptionsRequest, SubscriptionUserType } from "../models";


export class SubscriptionService extends BaseService implements ISubscriptionService {
  subscriptionStorage: ISubscriptionStorage;

  constructor(commonStorage: ICommonStorage, api: Api, subscriptionStorage: ISubscriptionStorage) {
    super(commonStorage, api);
    this.subscriptionStorage = subscriptionStorage;
  }

  private getLastSubscriptionId = (subscriptions: SubscriptionModel[]) : string | undefined => {
    return subscriptions.length > 0 ? subscriptions[subscriptions.length-1].id: undefined
  }

  public async getSubscriptionPackagePricingList() {
    try {
      const loadingKey = 'GET_PACKAGE_PRICING_LIST'
      this.commonStorage.setLoading(loadingKey, true);
      const subscriptionPackagePricingResponse = await this.api.subscriptions.getSubscriptionPackagePricingList();
      this.commonStorage.setLoading(loadingKey, false);
      return subscriptionPackagePricingResponse;
    } catch (error) {
      throw error;
    }
  }

  public async getSubscriptionSaleTypeList() {
    try {
      return await this.api.subscriptions.getSubscriptionSaleTypeList();
    } catch (error) {
      throw error;
    }
  }

  public async getSubscriptionUserTypeList() {
    try {
      return await this.api.subscriptions.getSubscriptionUserTypeList();
    } catch (error) {
      throw error;
    }
  }

  public async overrideSubscription(subscription: OverrideUserSubscriptionBody) {
    try {
      await this.api.subscriptions.overrideSubscription(subscription);
    } catch (error) {
      throw error;
    }
  }

  public async getSubscriptionList(queryParams?: SubscriptionQueryString) {
    try {
      const loadingKey = 'GET_SUBSCRIPTION_LIST'
      this.commonStorage.setLoading(loadingKey, true);
      const subscriptionReponse = await this.api.subscriptions.getSubscriptionList(queryParams);
      this.subscriptionStorage.setSubscriptions(toDict(subscriptionReponse, (s) => s.id))
      const lastCategoryId = this.getLastSubscriptionId(subscriptionReponse)
      this.subscriptionStorage.setGettingSubscriptionListRequest({
        ...queryParams,
        startingAfter: lastCategoryId || queryParams.startingAfter  
      } as GettingSubscriptionsRequest)
      this.subscriptionStorage.setSubscriptionsReachBottom(subscriptionReponse.length === 0)
      this.commonStorage.setLoading(loadingKey, false);
    } catch (error) {
      throw error;
    }
  }

  public async loadMore() {
    try {
      const subscriptionListManager = this.getState().subscriptions.subscriptionListManager;
      const queryParams = subscriptionListManager.request;
      if (!queryParams.startingAfter) {
        return
      }
      if(subscriptionListManager.hasReachedBottom) {
        return
      }
      if(subscriptionListManager.isLoadingMoreSubscription){
        return
      }
      this.subscriptionStorage.setLoadingMoreSubscriptions(true)
      const loadingKey = 'LOAD_MORE_SUBSCRIPTION_LIST'
      this.commonStorage.setLoading(loadingKey, true);
      const subscriptions = await this.api.subscriptions.getSubscriptionList(queryParams);
      this.subscriptionStorage.pushSubscriptions(toDict((subscriptions), (p: SubscriptionModel) => p.id));
      const lastSubscriptionId = this.getLastSubscriptionId(subscriptions)
      this.subscriptionStorage.setLoadingMoreSubscriptions(false)
      this.subscriptionStorage.setGettingSubscriptionListRequest({
        ...queryParams,
        startingAfter: lastSubscriptionId || queryParams.startingAfter  
      } as GettingSubscriptionsRequest)
      this.subscriptionStorage.setSubscriptionsReachBottom(subscriptions.length < queryParams.limit)
      this.commonStorage.setLoading(loadingKey, false);
    } catch (error) {
      throw error;
    }
  };

  public async cancelSubscription(subscriptionId: string) {
    try {
      await this.api.subscriptions.cancelSubscription(subscriptionId);
    } catch (error) {
      throw error;
    }
  }
}

export interface ISubscriptionService {
  getSubscriptionPackagePricingList: () => Promise<SubscriptionPackagePricing[]>;
  getSubscriptionSaleTypeList: () => Promise<SubscriptionSaleType[]>;
  getSubscriptionUserTypeList: () => Promise<SubscriptionUserType[]>;
  overrideSubscription: (subscription: OverrideUserSubscriptionBody) => Promise<void>;
  getSubscriptionList: (queryParams?: SubscriptionQueryString) => Promise<void>;
  loadMore: (queryString: SubscriptionQueryString) => Promise<void>;
  cancelSubscription: (subscriptionId: string) => Promise<void>;
}
