import React, { useContext, useRef, useEffect } from "react";
import { ObjectSchema, Shape, ValidationError } from "yup";
import { useState } from "react";
import Context, { FormContext } from "./context";
import { forEachDict } from "utilities/converter/list";
import { Fields, FieldData } from "./field";
import SubItemContext, { SubItemFormContext } from "./subItemContext";

interface FormProps<T extends object = any> {
  validationSchema?: ObjectSchema<Shape<T, T>>;
  onChange?: (values: T) => any;
  onChangeAtValid?: (values: T) => any;
  initialValues: T;
  render: (actions: FormAction<T>) => any;
}

export interface FormAction<T> {
  submit: () => any;
  validate: () => any;
  isValid: () => any;
  reset: () => any;
  getValues: () => T;
  validateAndGetValue: () => T;
  setFieldValue: <V>(mutate: (x: T) => any) => any,
  disable: () => any;
  enable: () => any;
  execute: (innerFunc: (x: FormAction<T>) => any) => () => any;
  setFieldError: (key: string, error: string) => any;
}

const Form = <T extends object>(props: FormProps<T>): any => {
  const valuesState = useState<T>(props.initialValues);
  const validityState = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const fieldsState = useState<Fields<T>>({});

  const valuesRef = useRef<T>(valuesState[0]);
  const fieldsRef = useRef<Fields<T>>(fieldsState[0]);
  const validityRef = useRef<boolean>(validityState[0]);

  function applyFields() {
    fieldsState[1]({ ...fieldsRef.current });
  }
  function applyValues() {
    valuesState[1]({ ...valuesRef.current });
    if(props.onChange){
      props.onChange(valuesRef.current)

    }

    if(props.onChangeAtValid && validityRef.current){
      props.onChangeAtValid(valuesRef.current)
    }
  }
  function applyValidity() {
    validityState[1](validityRef.current);
  }

  const onFieldTouched = (key: string) => {
    const fields = fieldsRef.current;

    if (!fields[key]) {
      return;
    }
    validateAll();
    fields[key] = {
      ...fields[key],
      isDirty: true
    };
    applyFields();
  };

  const validateAll = () => {
    validityRef.current = true;
    if (!props.validationSchema) {
      applyValidity();
      return;
    }

    const fields = fieldsRef.current;
    const values = valuesRef.current;

    forEachDict(fields, key => {
      fields[key] = {
        ...fields[key],
        error: undefined
      };
    });

    try {
      props.validationSchema.validateSync(values, {
        abortEarly: false
      });
    } catch (err) {
      validityRef.current = false;
      (err as ValidationError).inner.forEach((e: ValidationError) => {
        const { path, message } = e;
        fields[path] = {
          ...fields[path],
          error: message
        };
      });
    }
    applyValidity();
  };

  useEffect(() => {
    if(props.initialValues){
      validateAll()
    }
  },[props.initialValues, props.validationSchema])

  const setFieldValue = function<V>(key: string, value: V) {
    const fields = fieldsRef.current;
    let values = valuesRef.current;

    fields[key] = {
      ...fields[key],
      isDirty: true
    };
    const field: FieldData<T, V> = fields[key] as FieldData<T, V>;
    field.setValueFunc(values, value);

    validateAll();
    applyValues();
    applyFields();
  };

  const submit = async () => {
    if (props.validationSchema) {
      validateAll();
      applyFields();
    }
  };

  const getField = function<V>(key: string): FieldData<T> {
    return fieldsState[0][key];
  };

  const registerField = function<V extends any>(
    key: string,
    name: string,
    mapSubItem: (item: T) => V
  ): FieldData<T, V> {
    const fields = fieldsRef.current;

    let f = fields[key];

    if (!f || !f.setValueFunc || !f.name) {
      f = {
        ...f,
        setValueFunc: (values: T, val: any) => {
          mapSubItem(values)[name] = val;
        },
        name
      };
      fields[key] = f;
      applyFields();
    }

    return f;
  };
  const setFieldError = (key: string, error: string) => {
    const fields = fieldsRef.current;
    if (!fields[key]) {
      return;
    }
    fields[key].error = error;
    applyFields();
  };

  const reset = () => {
    const fields = fieldsRef.current;
    forEachDict(fields, key => {
      fields[key] = {
        ...fields[key],
        isDirty: false,
        error: undefined
      };
    });
    valuesRef.current = props.initialValues
    validityRef.current = false
    applyValidity()
    applyValues()
    applyFields();
  };

  const setAllDirty = () => {
    const fields = fieldsRef.current;

    forEachDict(fields, key => {
      fields[key] = {
        ...fields[key],
        isDirty: true,
      };
    });
  };

  const action: FormAction<T> = {
    submit,
    setFieldError,
    isValid: () => validityState[0],
    validate: () => {
      validateAll();
      applyFields();
    },
    setFieldValue: function<V>(mutate: (x: T) => any){
      const v = valuesRef.current
      mutate(v)
      applyValues()
    },
    reset,
    validateAndGetValue: () => {
      validateAll();
      applyFields();
      return valuesState[0];
    },
    getValues: () => valuesState[0],
    execute: function(innerFunc: (action: FormAction<T>) => any) {
      const t = this;
      return async () => {
        try {
          setAllDirty()
          t.validateAndGetValue();
          if (!t.isValid()) {
            throw "not valid";
          }
          t.disable();
          await innerFunc(t);
        } catch {}
        t.enable();
      };
    },
    disable: () => setIsDisabled(true),
    enable: () => setIsDisabled(false)
  };

  return (
    <Context.Provider
      value={{
        values: valuesState[0],
        isDisabled,
        getField,
        onFieldTouched,
        registerField,
        setFieldValue
      }}
    >
      {props.render(action)}
    </Context.Provider>
  );
};

export interface FieldProps {
  name: string;
  render: (renderingFieldContext: RenderingFieldContext) => React.ReactNode;
}

export interface RenderingFieldContext<T = any> {
  field: FieldData<T>;
  value: any;
  isDisabled: boolean;
  onBlur: () => any;
  onChange: (value: any) => any;
}

function Field<T>(props: FieldProps): any {
  const ctx: FormContext = useContext(Context);
  const subCtx: SubItemFormContext = useContext(SubItemContext) || {
    prefixName: "",
    mapSubItem: x => x
  };

  const key = subCtx.prefixName + props.name;
  const field = ctx.registerField(key, props.name, subCtx.mapSubItem);
  return props.render({
    field,
    isDisabled: ctx.isDisabled,
    value: ctx.values[key],
    onBlur: () => ctx.onFieldTouched(key),
    onChange: (value: any) => ctx.setFieldValue(key, value)
  });
}

export interface ArrayHelperProps<T, Item extends Object> {
  name: string;
  forEach: (values: T) => Item[];
  render: (x: Item, index: number) => React.ReactNode;
}

function ArrayHelper<T, Item extends Object>(
  props: ArrayHelperProps<T, Item>
): any {
  const ctx: FormContext = useContext(Context);
  const list = props.forEach(ctx.values);
  return (
    <React.Fragment>
      {list.map((x, index) => (
        <SubItemContext.Provider
          value={{
            mapSubItem: x => props.forEach(x)[index],
            prefixName: props.name + "[" + index + "]."
          }}
        >
          {props.render(x, index)}
        </SubItemContext.Provider>
      ))}
    </React.Fragment>
  );
}

export default {
  ArrayHelper,
  Form,
  Field
};
