import React from 'react'
import { FieldData } from './field';



export interface FormContext<T extends object=any> {
    values: T,
    isDisabled: boolean,
    getField: <X>(key: string) => FieldData<T,X>,
    onFieldTouched:  (key: string) => any,
    registerField:  <V>(key: string, name: string, mapItemFunc: (item: T)=> any) => FieldData<T, V>,
    setFieldValue: <V>(key: string, value: V) => any
}

export default React.createContext<FormContext<any>>({
    values: {},
    isDisabled: false,
    getField: <X>(key: string) => ({
        name: "",
        setValueFunc:  (values: any, val: X) => ({...values})
    }),
    registerField:  (key: string, name: string) => ({
        setValueFunc: (vs, v) => vs,
        name,
    }),
    onFieldTouched: (key: string) => {},    
    setFieldValue: <V>(key: string, value: V) => {}
});

