import React, { useState, useEffect } from "react";
import InputBase from "../base";
import { Icon, Text, Form } from "views/components";
import "./style.css";
import classNames from "classnames";
import { RenderingFieldContext } from "views/components/Form";
import { Dropdown, DropdownAction } from "views/components/Dropdown";

export interface DropdownItem<T = any> {
  value: T;
  label: string;
  isDisabled?: boolean;
}

interface Props<T> {
  label?: string;
  placeholder?: string;
  list: DropdownItem<T>[];
  name: string;
  onChange?: (value: T) => any;
  value?: T;
  isDisabled?: boolean;
  className?: string;
  style?:any;
  suffix?:any;
}

export default <T extends any>(props: Props<T>) => {
  const valueState = useState<T>(props.value);

  useEffect(() => {
    if (props.value) {
      handlePropsOnChange(props.value);
    }
  }, [props.value]);

  const renderDropdownAndOverlay = (
    { field, value, onChange, onBlur }: RenderingFieldContext,
    { setVisibility, isVisible, toggle }: DropdownAction
  ): [React.ReactNode, React.ReactNode] => {
    if (value != valueState[0]) {
      updateSelection(value);
      handlePropsOnChange(value);
    }

    const onSelect = (item: DropdownItem<T>) => {
      const newItem = {
        ...item,
      };
      onChange(newItem.value);
      updateSelection(item.value);
      handlePropsOnChange(item.value);
      setVisibility(false);
    };
    const renderList = () =>
      props.list.map((item, i) => {
        const isSelected = valueState[0] === item.value;
        if (item.value !== undefined && item.label !== undefined) {
          return (
            <li
              className={classNames(
                { disabled: item.isDisabled },
                { selected: isSelected }
              )}
              onClick={() => !item.isDisabled && onSelect(item)}
              key={i}
            >
              <Text.Span dangerousContent={item.label} size={14}></Text.Span>
              {isSelected && <Icon.Checklist />}
            </li>
          );
        }
        return null;
      });

    const selectedItem = getSelectedItem();
    return [
      <div className="inner" onClick={toggle} style={{ position: "relative" }}>
        {selectedItem && selectedItem.label ? (
          <Text.Span dangerousContent={selectedItem.label}></Text.Span>
        ) : (
          <Text.Span
            className="dropdown-placeholder"
            color="gray"
          >
            {props.placeholder}
          </Text.Span>
        )}
        <div
          onClick={toggle}
          style={{
            width: "25px",
            height: "35px",
            position: "absolute",
            backgroundColor: "transparent",
            right: "-25px",
            top: "0",
            zIndex: 999,
          }}
        />
      </div>,
      <ul
        style={{ maxHeight: "200px", overflowY: "auto" }}
        className={`post-input-select-option ${
          !props.isDisabled && isVisible ? "visible" : "hidden"
        }`}
      >
        {props.list && renderList()}
      </ul>,
    ];
  };

  const handlePropsOnChange = (value?: T) => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  const getSelectedItem = (): DropdownItem<T> | undefined => {
    return props.list.filter((item) => item.value === valueState[0])[0];
  };

  const updateSelection = (value: T) => {
    valueState[1](value);
  };

  const renderInner = (fieldContext: RenderingFieldContext) => {
    const isDisabled = fieldContext.isDisabled || props.isDisabled;
    return (
      <InputBase
        label={props.label}
        isDisabled={isDisabled}
        className={props.className}
        suffix={props.suffix ? <Icon.Caretdown color="black" /> : null}
        error={fieldContext.field.isDirty && fieldContext.field.error}
      >
        <Dropdown
          className="post-input-select"
          onClose={() => fieldContext.onBlur()}
          style={props.style}
          alwaysRenderOverlay={true}
          render={(dropdownAction) =>
            renderDropdownAndOverlay(fieldContext, dropdownAction)
          }
        ></Dropdown>
      </InputBase>
    );
  };
  return <Form.Field name={props.name} render={renderInner} />;
};
