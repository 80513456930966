import React from "react";
import classNames from "classnames";
import "./style.css";
import { BaseClickableProps } from "../props";
import Text from "../Text";

interface Props extends BaseClickableProps {
  theme:
    | "primary"
    | "secondary"
    | "danger"
    | "transparent"
    | "netral"
    | "inactive"
    | "disable"
    | "dangeroutline"
    | "success"
    | "normal"
    | "purple"
    | "purple_secondary";
  label?: any;
  size?: "small" | "medium" | "large";
  fullW?: boolean;
  type?: "button" | "submit" | "reset";
  round?: boolean;
  disabled?: boolean;
  compact?: boolean;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  style?: React.CSSProperties;
}

const Button: React.FC<Props> = (props) => {
  const className = classNames(
    props.compact ? "" : "default",
    props.className || "",
    "post-button",
    props.theme,
    { "full-width": props.fullW },
    { rounded: props.round },
    "post-button-" + (props.size || "medium")
  );
  return (
    <button
      id={props.id}
      disabled={props.disabled}
      type={props.type || "button"}
      className={className}
      onMouseOver={props.onMouseOver}
      onClick={props.onClick}
      data-testid="button"
      style={props.style}
    >
      {props.children ? (
        props.children
      ) : (
        <div className="post-button-inner">
          {props.iconLeft}
          <Text.Heading h={6} align="center" noMargin>
            {props.label}
          </Text.Heading>
          {props.iconRight}
        </div>
      )}
    </button>
  );
};

export default Button;
