import React from 'react'
import classNames from 'classnames';
import './style.css';
import { useSelector } from 'react-redux';
import { AlertConfig } from 'domains/commons/states';
import selector from './selector'
import { commonServices } from 'injectors';


interface Props {
  config?: AlertConfig
}

const Alert = (props: Props) => {
  const config = useSelector(selector);
  if (config) {
    setTimeout(() => {
      commonServices.setAlert(undefined)
    }, config.timeout || 3000)
  }

  const className = classNames(
    'post-alert' , 
    'post-alert-'+((config && config.type) ||  'success'),
    {'hidden': !config},
    {'visible': !!config}
  )
  return (
    <div className={className}>
        {config && config.message}
    </div>
  )
}

export default Alert;
export{
    Alert
}
