import React, { useState } from 'react'
import InputBase from '../base'
import { Icon } from 'views/components'
import Form, { RenderingFieldContext } from '../../Form'

interface Props {
  label?: string;
  placeholder?: string;
  name?: string;
}

const InputPassword = (props: Props) => {

  const [isShown, setIsShown] = useState(false)
  const [fieldType, setFieldType] = useState("password")

  const renderEyeIcon = () => {
    return (
      <div onClick={() => handleShowPassword(isShown)}>
        {isShown ? <Icon.EyeShow /> : <Icon.EyeHidden />}
      </div>
    )
  }

  const handleShowPassword = (isShown: boolean) => {
    setIsShown(!isShown)
    if (!isShown === true) {
      setFieldType('text')
    } else {
      setFieldType('password')
    }
  }



  return (
    <Form.Field
      name={props.name}
      render={({ field, value, onChange,isDisabled, onBlur }: RenderingFieldContext) => (

        <div style={{ position: 'relative' }} >
          <InputBase
            suffix={renderEyeIcon()}
            isDisabled={isDisabled}
            label={props.label}
            error={field.isDirty && field.error}>

            <input            
              name={props.name}
              type={fieldType}
              disabled={isDisabled}
              value={value}
              placeholder={props.placeholder}
              onBlur={onBlur}
              onChange={(evt) => onChange(evt.target.value)}
            />
          </InputBase>
        </div>
      )}
    />
  )
}

export default InputPassword;