import React, { useEffect } from 'react'
import { Provider } from 'react-redux';
import { Router, Switch, Route } from 'react-router';
import AuthorizedLayout from 'views/layouts/Authorized';
import { history, AppStore } from 'storages/store';
import UnauthorizedLayout from 'views/layouts/Unauthorized';
import { Alert } from 'views/components';
import { ModalContainer } from 'views/components/Modal';
import { getLocalStorage } from 'utilities/localStorage';
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage';
import { authServices } from 'injectors';
import { LoginRequest } from 'domains/auth/models';

const App: React.FC = () => {
  useEffect(() => {
    if (getLocalStorage(CONFIG_LOCALSTORAGE.POST_TOKEN)) {
      const query: LoginRequest = {
        email: getLocalStorage(CONFIG_LOCALSTORAGE.EMAIL),
        googleTokenId: getLocalStorage(CONFIG_LOCALSTORAGE.GOOGLE_TOKEN_ID)
      }
      authServices.reLogin(query)
    }
  }, [])

  return (
    <Provider store={AppStore}>
      <Alert />
      <Router history={history}>
        <Switch>
          <Route path="/auth" component={UnauthorizedLayout} />
          <Route path="/" component={AuthorizedLayout} />
        </Switch>
        <ModalContainer />
      </Router>
    </Provider>
  )
}

export default App;
