import { FunctionComponent } from 'react'
import { Dict } from 'utilities/type'

export interface ModalConfig {
    path: string
}


const modalConfigDict: Dict<ModalConfig> = {}

export function registerModalConfig(fc: FunctionComponent<any>, config: ModalConfig){
    modalConfigDict[fc.name] = config
}

export function getModalConfig(fc: FunctionComponent<any>): (ModalConfig | undefined){
    return modalConfigDict[fc.name]
}