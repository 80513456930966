import React, { useState } from "react";
import Text from "views/components/Text";
import "./style.css";
import classNames from "classnames";
import { Sided } from "..";

interface Props {
  label?: string;
  children?: React.ReactChild;
  isDisabled?: boolean;
  className?: string;
  error?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}

const InputBase = (props: Props) => {
  const [isFocus, setIsFocus] = useState(false);

  const label = props.label && (
    <Text.Paragraph size={12} color="black">
      {props.label}
    </Text.Paragraph>
  );

  // const className = classNames(
  //   "",
  //   props.className,
  //   props.isDisabled && "disabled",
  //   { focus: isFocus }
  // );

  const classNameBase = classNames("post-input-base", { focus: isFocus });

  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      // className={className}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
    >
      <Sided.Sided gutter={0} className={classNameBase}>
        {props.prefix && (
          <Sided.Fixed className="input-prefix">{props.prefix}</Sided.Fixed>
        )}
        <Sided.Remaining>{props.children}</Sided.Remaining>
        {props.suffix && (
          <Sided.Fixed className="input-suffix">{props.suffix}</Sided.Fixed>
        )}
      </Sided.Sided>

      {label}
      {props.error && (
        <Text.Span color="red" size={12}>
          {props.error}
        </Text.Span>
      )}
    </div>
  );
};

export default InputBase;
