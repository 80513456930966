import { Reducer } from "utilities/redux/reducer";
import { Dict } from "utilities/type";
import { PaymentMethodKYCState } from 'domains/paymentMethodKYC/states/index';
import { PaymentMethodKYCForm, GettingPaymentMethodKYCRequest } from "domains/paymentMethodKYC/models";
import { SET_PAYMENT_METHOD_KYC_DICT, PUSH_PAYMENT_METHOD_KYC, SET_PAYMENT_METHOD_KYC_LIST_REACH_BOTTOM, SET_LOADING_MORE_PAYMENT_METHOD_KYC, SET_GETTING_PAYMENT_METHOD_KYC_LIST_REQUEST } from './actions';

export default class PaymentMethodKYCReducer extends Reducer<PaymentMethodKYCState> {
  constructor() {
    super({
      paymentMethodKYCFormDict: {},
      paymentMethodKYCListManager: {
        isLoadingMore: false,
        request: {},
        hasReachedBottom: false
      },
    })
  }

  public setPaymentMethodKYCDict(state: PaymentMethodKYCState, paymentMethodKYCFormDict: Dict<PaymentMethodKYCForm>) {
    return {
      ...state,
      paymentMethodKYCFormDict
    }
  }

  public pushPaymentMethodKYCList(state: PaymentMethodKYCState, paymentMethodKYCDict: Dict<PaymentMethodKYCForm>) {
    return {
      ...state,
      paymentMethodKYCFormDict: {
        ...state.paymentMethodKYCFormDict,
        ...paymentMethodKYCDict
      }
    };
  };

  public setListReachBottom(state: PaymentMethodKYCState, hasReachedBottom: boolean) {
    return {
      ...state,
      paymentMethodKYCListManager: {
        ...state.paymentMethodKYCListManager,
        hasReachedBottom
      }
    }
  }

  public setLoadingMore(state: PaymentMethodKYCState, isLoadingMore: boolean) {
    return {
      ...state,
      paymentMethodKYCListManager: {
        ...state.paymentMethodKYCListManager,
        isLoadingMore
      }
    }
  }


  public setGettingListRequest(state: PaymentMethodKYCState, request: GettingPaymentMethodKYCRequest) {
    return {
      ...state,
      paymentMethodKYCListManager: {
        ...state.paymentMethodKYCListManager,
        request
      }
    }
  }

  get actions(): {
    [p: string]: (state: PaymentMethodKYCState, payload?: any) => any;
  } {
    return {
      [SET_PAYMENT_METHOD_KYC_DICT]: this.setPaymentMethodKYCDict,
      [PUSH_PAYMENT_METHOD_KYC]: this.pushPaymentMethodKYCList,
      [SET_PAYMENT_METHOD_KYC_LIST_REACH_BOTTOM]: this.setListReachBottom,
      [SET_LOADING_MORE_PAYMENT_METHOD_KYC]: this.setLoadingMore,
      [SET_GETTING_PAYMENT_METHOD_KYC_LIST_REQUEST]: this.setGettingListRequest
    };
  }
}
