const appRoute = {
  SUBSCRIPTION: "/subscription",
  SUBSCRIPTION_VOUCHER: "/subscription-voucher",
  SUBSCRIPTION_VOUCHER_CREATE: "/subscription-voucher/create",
  SUBSCRIPTION_VOUCHER_EDIT: "/subscription-voucher/edit",
  CASHLEZ_KYC: "/cashlez-kyc",
  CASHLEZ_KYC_DETAIL: "/cashlez-kyc/detail",
  SHOPEEPAY_KYC: "/shopeepay-kyc",
  SHOPEEPAY_KYC_DETAIL: "/shopeepay-kyc/detail",
  TRANSACTION_REPORT: "/transaction-report",
  DISBURSEMENT: "/disbursement",
  TOKOFI_QRIS_DISBURSEMENT: "/tokofi-qris-disbursement",
  TOKOFI_QRIS_DISBURSEMENT_BULK_UPDATE: "/tokofi-qris-disbursement/bulk-update",
  CONTACTLESS: "/easyorder",
  CONTACTLESS_DETAIL: "/easyorder/:id",
  FLEXPAY_INVOICE: "/flexpay-invoice",
  FLEXPAY_INVOICE_DETAIL: "/flexpay-invoice/detail",
  FLEXPAY_PAYMENT: "/flexpay-payment",
  FLEXPAY_PAYMENT_DETAIL: "/flexpay-payment/detail",
  FLEXPAY_PAYMENT_CREATE: "/flexpay-payment/create",
  FLEXPAY_VENDOR: "/flexpay-vendor",
  FLEXPAY_VENDOR_CREATE: "/flexpay-vendor/create",
  FLEXPAY_VENDOR_DETAIL: "/flexpay-vendor/detail",
  FLEXPAY_USER: "/flexpay-user",
  FLEXPAY_USER_DETAIL: "/flexpay-user/detail",
  FLEXPAY_USER_DETAIL_WALLET: "/flexpay-user/detail-wallet",
  FLEXPAY_BILL: "/flexpay-bill",
  FLEXPAY_BILL_DETAIL: "/flexpay-bill/detail",
  FLEXPAY_BILL_DAILY: "/flexpay-bill-daily",
  FLEXPAY_BILL_DETAIL_DAILY: "/flexpay-bill/detail-daily",
  FLEXPAY_BILL_CREATE: "/flexpay-bill/create",
  FLEXPAY_REPAYMENT: "/flexpay-repayment",
  FLEXPAY_REPAYMENT_DETAIL: "/flexpay-repayment/detail",
  FLEXPAY_REPAYMENT_CREATE: "/flexpay-repayment/create",
  FLEXPAY_ACCUMULATIVE_AMOUNT_DUE:"/flexpay-accumulative/amount-due"
};

export default appRoute;
