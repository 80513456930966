import {
  ShopeeOrderModel,
  GetShopeeOrderQueryString,
  GettingShopeeOrderRequest,
} from "../models";
import { BaseService } from "domains/commons/services";
import { ICommonStorage } from "domains/commons/contracts/storage";
import { Api } from "domains/contracts/api";
import { IShopeeOrderStorage } from "../contracts/storage";
import { toDict } from "utilities/converter/list";

export class ShopeeOrderService
  extends BaseService
  implements IShopeeOrderService
{
  orderStorage: IShopeeOrderStorage;

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    orderStorage: IShopeeOrderStorage
  ) {
    super(commonStorage, api);
    this.orderStorage = orderStorage;
  }

  private getLastShopeeOrderId = (
    shopeeOrder: ShopeeOrderModel[]
  ): string | undefined => {
    return shopeeOrder.length > 0
      ? shopeeOrder[shopeeOrder.length - 1].id
      : undefined;
  };

  public async getShopeeOrderList(queryParams?: GetShopeeOrderQueryString) {
    try {
      const loadingKey = "GET_SHOPEE_ORDER_LIST";
      this.commonStorage.setLoading(loadingKey, true);
      const shopeeOrderReponse = await this.api.order.getShopeeOrderList(
        queryParams
      );
      this.orderStorage.setShopeeOrders(
        toDict(shopeeOrderReponse, (s) => s.id)
      );
      this.commonStorage.setLoading(loadingKey, false);
      const lastPaymentMethodKYCFormId =
        this.getLastShopeeOrderId(shopeeOrderReponse);
      this.orderStorage.setGettingListRequest({
        ...queryParams,
        startingAfter: lastPaymentMethodKYCFormId || queryParams.startingAfter,
      } as GetShopeeOrderQueryString);
      this.orderStorage.setListReachBottom(
        shopeeOrderReponse.length < queryParams.limit
      );
      return shopeeOrderReponse;
    } catch (error) {
      throw error;
    }
  }

  public async loadMore(directQueryParams?: GetShopeeOrderQueryString) {
    try {
      const { shopeeOrderListManager } = this.getState().orders;
      const queryParams: GetShopeeOrderQueryString = {
        ...shopeeOrderListManager.request,
        toDate:
          shopeeOrderListManager.request.toDate || directQueryParams.toDate,
        fromDate:
          shopeeOrderListManager.request.fromDate || directQueryParams.fromDate,
      };
      if (!queryParams.startingAfter) {
        return;
      }
      if (shopeeOrderListManager.hasReachedBottom) {
        return;
      }
      if (shopeeOrderListManager.isLoadingMore) {
        return;
      }
      this.orderStorage.setLoadingMore(true);
      const loadingKey = "LOAD_MORE_SHOPEE_ORDER_LIST";
      this.commonStorage.setLoading(loadingKey, true);
      console.error("object", queryParams);
      const shopeeOrderReponse = await this.api.order.getShopeeOrderList(
        queryParams
      );
      this.orderStorage.pushShopeeOrders(
        toDict(shopeeOrderReponse, (p: ShopeeOrderModel) => p.orderNo)
      );
      const lastSubscriptionId = this.getLastShopeeOrderId(shopeeOrderReponse);
      this.orderStorage.setLoadingMore(false);
      this.orderStorage.setGettingListRequest({
        ...queryParams,
        startingAfter: lastSubscriptionId || queryParams.startingAfter,
      } as GettingShopeeOrderRequest);
      this.orderStorage.setListReachBottom(
        shopeeOrderReponse.length < queryParams.limit ||
          shopeeOrderReponse.length === 0
      );
      this.commonStorage.setLoading(loadingKey, false);
    } catch (error) {
      throw error;
    }
  }

  public async getAllShopeeOrderList(queryParams?: GetShopeeOrderQueryString) {
    try {
      const shopeeOrderReponse = await this.api.order.getShopeeOrderList(
        queryParams
      );
      return shopeeOrderReponse;
    } catch (error) {
      throw error;
    }
  }
}

export interface IShopeeOrderService {
  getShopeeOrderList: (
    queryParams?: GetShopeeOrderQueryString
  ) => Promise<ShopeeOrderModel[]>;
  loadMore: (queryParams?: GetShopeeOrderQueryString) => Promise<void>;
  getAllShopeeOrderList: (
    queryParams?: GetShopeeOrderQueryString
  ) => Promise<ShopeeOrderModel[]>;
}
