export type MiddleWareFunction = () => Promise<boolean | string>;

export interface RouteItem<Component = any> extends RouteDescription {
  component?: Component;
  children?: Array<RouteItem>;
}
export interface RouteDescription {
  key: string;
  text?: string;
  path?: string;
  icon?: JSX.Element;
  middleware?: Array<MiddleWareFunction>;
  hideSidebar?: boolean;
  childrenDescription?: Array<RouteDescription>;
  isEnabled: boolean;
  redirectTo?: string;
}

export function toRouteDescription(i: RouteItem): RouteDescription {
  let x = {
    ...i,
  };
  if (x.children) {
    x.childrenDescription = x.children.map(toRouteDescription);
  }
  delete x.component;
  delete x.children;
  return x;
}
